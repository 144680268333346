<template>
    <button type="button" class="btn btn-primary m-0" @click="newPost">New Post</button>
</template>
<script>
    import { mapActions } from 'vuex';

    export default {
        name: 'AddNewBlogButton',
        methods: {
            newPost(){
                 this.$router.push({ name: 'BlogFormNew' });
            },
            ...mapActions('blog/addModifyBlog', [
                'resetBlog'
            ])
        }
    }
</script>