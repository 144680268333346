<template>
    <div class="header-template-2" :data-variant="variant">
        <div class="dynamic-container">
            <div class="primary-links">
                <vue-link
                    v-for="link in primaryLinks"
                    :key="link.text"
                    :link-obj="link"
                ></vue-link>
            </div>
            <div class="logo">
                <img :src="logoPath" alt="Company logo">
                <vue-link link-path="/" link-text="Company name home" link-class="absolute-fill text-throw"></vue-link>
            </div>
            <div class="secondary-links">
                <template v-if="secondaryLinks">
                    <vue-link
                        v-for="link in secondaryLinks"
                        :key="link.text"
                        :link-obj="link"
                    ></vue-link>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { themingMixin } from '../../../Mixins/themingMixin';
import { navMixin } from '../../../Mixins/navMixin';
import { headerMixin } from '../../../Mixins/headerMixin';

export default {
    mixins: [
        themingMixin,
        navMixin,
        headerMixin
    ],
    props: [
        "primaryLinks",
        "secondaryLinks",
    ],
}
</script>

<style lang="scss" scoped>
    $theme-color: var(--theme-color, 255, 255, 255);
    $theme-complement: var(--theme-complement, 0, 0, 0);
    $theme-complement-compliant: var(--theme-complement-compliant, 255, 255, 255);

    .logo {
        flex-shrink: 0;
        position: relative;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
            height: 100%;
            vertical-align: bottom;
        }
    }

    .header-template-2 { background-color: rgb($theme-color); }

    .dynamic-container {
        display: flex;
        align-items: center;
        justify-content: center;

        .all-links {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        // Default container variant
        .header-template-2:not([data-variant]) & {
            max-width: var(--container-width, 1140px);
            margin-left: auto;
            margin-right: auto;
            padding-left: calc((var(--grid-gutter-width, 30px) / 2));
            padding-right: calc((var(--grid-gutter-width, 30px) / 2));
        }

        // Full width variant
        .header-template-2[data-variant="full"] & {
            padding-left: calc((var(--grid-gutter-width, 30px) / 2));
            padding-right: calc((var(--grid-gutter-width, 30px) / 2));
        }
    }

    .primary-links,
    .secondary-links {
        flex: 1 1 0;
        display: flex;

        a {
            color: white;
            padding: .5rem 1rem;
        }
    }

    .primary-links { justify-content: flex-start; }
    .secondary-links { justify-content: flex-end; }
</style>