<template>
    <div class="container">
        <h3 class="text-blue text-center mb-0">Please enter your keywords</h3>
        <div class="mt-4 mx-auto w-md-50">
            <search-form :search-field="getSearchFieldValue" form-target="_self"></search-form>
        </div>
        <div class="mt-4 text-center">
            <h5>Tips for better results</h5>
            <p class="mb-1 text-14">If you're searching for a specific couple, please enter either of their last names.</p>
            <p class="mb-1 text-14">If you're looking for a vendor, please enter their business name.</p>
            <p class="mb-1 text-14">For more Vendor Specific search capabilities, <a data-target="#bridal-registration-form-modal" data-toggle="modal" href="#" title="Opens modal with couple's registration form">please register</a> to create a free couple's account.</p>
        </div>
    </div>
</template>
<script>
    import SearchForm from './SearchForm';
    export default {
        components: {
            SearchForm,
        },
        computed: {
            getSearchFieldValue(){
                let urlParams = new URLSearchParams(window.location.search);
                return urlParams.has('q') ? urlParams.get('q') : '';
            }
        }
    }
</script>