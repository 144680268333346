<template>
    <vue-modal :visible="isModalOpen" @on-close="closeRecycleModal" id="tag-recycle-modal">
        <template #header>
            <h5 class="modal-title"><span class="fas fa-trash mr-1 text-tertiary"></span>Confirm Tag Delete</h5>
        </template>

        <div class="d-flex flex-column align-items-center">
            <span class="fas fa-exclamation-triangle text-danger fa-7x"></span>
            <p class="h4 font-weight-bold">Are you sure you want to delete this tag?</p>
            <p>This action is permanent. Consider marking the tag as inactive.</p>
        </div>

        <template #footer>
            <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-outline-primary cancel-btn" @click="closeRecycleModal">Cancel</button>
                <button type="button" class="btn btn-primary confirm-btn ml-2" @click="recycleTag" :disabled="isModalProcessing">{{ isModalProcessing ? 'Processing...' : 'Delete tag' }}</button>
            </div>
        </template>
    </vue-modal>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: 'RecycleTagModal',
        computed: {
            ...mapState('tag', {
                activeRecord: state => state.addModifyTag.activeRecord,
                isModalOpen: state => state.recycleTagModal.isModalOpen,
                isModalProcessing: state => state.recycleTagModal.isModalProcessing,
            })
        },
        methods: {
            ...mapActions('tag', {
                closeModal: 'recycleTagModal/closeModal',
                resetTagModal: 'addModifyTag/resetTagModal',
                recycleRecord: 'recycleTagModal/recycleRecord',
            }),
            closeRecycleModal(){
                this.resetTagModal();
                this.closeModal();
            },
            recycleTag(){
                this.recycleRecord({ ajaxRoute: '/vpanel/modules/blog/tag/destroy/'+this.activeRecord.id, recordId: this.activeRecord.id, dispatchAction: 'tag/list/removeRecordFromDataTable'});
                this.resetTagModal();
            }
        }
    }
</script>