<template>
    <nav
        class="vue-nav-2"
        :data-expanded="isExpanded"
        :data-headertemplate="template"
        :data-navtemplate="navTemplate"
    >
        <div class="mobile-controls">
            <p class="close-mobile-menu" @click="closeMobileMenu">
                <span class="fas fa-chevron-left"></span>
            </p>
        </div>
        <template v-for="ni in navInfo">
            <vue-nav-item
                :depth="0"
                :nav-item="ni"
                :key="ni.page.uuid"
                v-if="ni.page.show_in_nav"
                :current-page-id="currentPageId"
            ></vue-nav-item>
        </template>
    </nav>
</template>

<script>
export default {
    props: [
        "currentPageId",
        "mobileExpanded",
        "navInfo",
        "navTemplate",
        "template"
    ],
    computed: {
        isExpanded() {
            return this.mobileExpanded;
        }
    },
    methods: {
        closeMobileMenu(event) {
            this.$emit('close-mobile-menu');
        },
        checkBounds() {
            let dropdowns = document.querySelectorAll('[data-depth="0"].vn-children');
            Array.from(dropdowns).forEach(dropdown => {
                let bounds = dropdown.getBoundingClientRect();
                if ((bounds.right > window.innerWidth) || (bounds.right > document.documentElement.clientWidth)) dropdown.classList.add('wide');
            })
        }
    },
    mounted() {
        let vm = this;
        vm.$nextTick(function() {
            setTimeout(function() {
                vm.checkBounds();
            },100);
        })
    }
}
</script>

<style lang="scss" scoped>
    @import '../../../../../../sass/vue-import';
    $theme-color: var(--theme-color, 255,255,255);
    $theme-compliant: var(--theme-compliant, 0,0,0);
    $theme-complement: var(--theme-complement, 255,255,255);

    .vue-nav-2 {
        @include fluid-size(16px, 32px, --top-padding, $min-vw: $lg-screen, $max-vw: 1400px);
        @include fluid-size(8px, 12px, --top-arrow-size, $min-vw: $lg-screen, $max-vw: 1400px);
        z-index: 2;

        @include desktop {
            position: relative;
            display: flex;
            margin-right: calc(-1 * var(--top-padding));
            align-items: stretch;

            .mobile-controls { display: none }

            &::v-deep {
                // Global Styling
                .vn-item {
                    cursor: pointer;
                    margin: 0;
                    line-height: 1.25;
                    color: rgb($theme-compliant);
                    text-decoration: none;

                    &:hover { text-decoration: underline; }
                }

                // Root Level Families
                [data-depth="0"] {
                    &.vn-family {
                        flex-shrink: 0;
                        display: flex;
                        align-items: center;

                        // When hovering over a family...
                        &:hover {
                            [data-depth="0"] {
                                &.vn-item:before { border-width: 0 calc(var(--top-arrow-size) * 5) calc(var(--top-arrow-size) * 2); }
                                &.vn-children { visibility: visible; }
                            }
                        }

                        // If family is current and in the top level nav
                        &[data-current] [data-depth="0"].vn-item:before { border-width: 0 calc(var(--top-arrow-size) * 5) calc(var(--top-arrow-size) * 2); }
                    }

                    &.vn-item {
                        @include fluid-size(18px, 24px, font-size, $min-vw: $lg-screen, $max-vw: 1400px);
                        padding: var(--top-padding);
                        position: relative;
                        display: flex;
                        align-items: center;
                        font-family: 'Roboto Condensed', sans-serif;
                        color: white;
                        text-decoration: none;
                        text-transform: uppercase;

                        &:before {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            height: calc(var(--top-arrow-size) * 2);
                            width: calc(var(--top-arrow-size) * 10);
                            left: 50%;
                            transform: translateX(-50%);
                            border-style: solid;
                            border-color: white transparent;
                            border-width: 0 calc(var(--top-arrow-size) * 5);
                            transition: 200ms ease-out;
                        }
                    }

                    &.vn-children {
                        display: flex;
                        align-items: stretch;
                        visibility: hidden;
                        position: absolute;
                        top: 100%;
                        background-color: rgb($theme-compliant);
                        box-shadow: 0 10px 10px -5px rgba(0,0,0,0.3);

                        &.wide {
                            right: 0;
                            max-width: 100vw;
                        }
                    }
                }

                // First Level Families
                [data-depth="1"] {
                    &.vn-family {
                        flex: 1 1 0;
                        display: flex;
                        width: max-content;
                        flex-direction: column;
                        padding: 2rem;
                    }

                    &.vn-item {
                        color: rgb($theme-color);
                        font-size: 22px;
                        width: max-content;
                        max-width: 400px;
                    }

                    &.vn-children {
                        margin-top: 1rem;
                    }
                }

                // Second Level Families
                [data-depth="2"] {
                    .vn-item {
                        display: block;
                        font-size: 16px;
                        padding: .5rem 0;
                        color: rgb($theme-color);
                    }
                }
            }
        }

        @include not-desktop {
            position: fixed;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            background-color: rgb($theme-color);
            height: 100vh;
            width: 100%;
            top: 0;
            right: -100%;
            transition: 200ms ease-out;

            &[data-expanded] { right: 0; }

            .mobile-controls {
                display: flex;
                background-color: rgb($theme-compliant);

                .close-mobile-menu {
                    color: rgb($theme-color);
                    margin: 0;
                    padding: 1rem;
                }
            }

            &::v-deep {
                .vn-item {
                    color: rgb($theme-compliant);
                    font-family: var(--body-font-family);
                    font-weight: bold;
                    line-height: 1;
                    text-transform: uppercase;
                }

                p.vn-item { pointer-events: none; }

                // Root Level Families
                [data-depth="0"] {
                    &.vn-family {
                        background-color: rgb($theme-color);
                        border-bottom: 3px solid rgba($theme-compliant, .5);
                        position: relative;

                        &[data-has-children] {
                            &:before, &:after {
                                content: '';
                                position: absolute;
                                pointer-events: none;
                            }

                            &:before {
                                width: 40px;
                                height: 40px;
                                top: 7px;
                                right: 7px;
                                border-radius: 50rem;
                                background-color: rgb($theme-compliant);
                            }

                            &:after {
                                border-width: 3px;
                                border-style: solid;
                                border-color: transparent rgb($theme-color) rgb($theme-color) transparent;
                                height: 14px;
                                width: 14px;
                                transform: rotate(45deg) translate(-2px, -2px);
                                transform-origin: 50% 50%;
                                right: 20px;
                                top: 20px;
                                transition: 200ms ease-out;
                            }

                            &[data-expanded] {
                                &:after {
                                    transform: rotate(-135deg) translate(-2px, -2px);
                                }

                                & > .vn-children {
                                    max-height: 50vh;
                                    transition: 200ms ease-in;
                                }
                            }
                        }
                    }

                    &.vn-item {
                        @include rem(22px);
                        padding: 1rem 54px 1rem 1rem;
                        display: inline-block;
                    }

                    &.vn-children {
                        display: flex;
                        flex-direction: column;
                        max-height: 0;
                        height: max-content;
                        overflow: hidden;
                        transition: 200ms ease-out;
                    }
                }

                [data-depth="1"] {
                    &.vn-family {
                        background-color: rgb($theme-compliant);
                        border-bottom: 1px solid rgb($theme-color);

                        &:last-child { border-width: 0; }

                        .vn-item {
                            color: rgb($theme-color);
                        }
                    }

                    &.vn-item {
                        @include rem(16px);
                        padding: .5rem 1rem;
                        display: block;
                        position: relative;
                        margin-right: 50%;
                    }
                }

                [data-depth="2"] {
                    &.vn-item {
                        @include rem(14px);
                        font-weight: normal;
                        text-transform: none;
                        padding: .5rem 1.5rem;
                        display: block;
                        position: relative;
                        margin-right: 50%;
                    }
                }

                [data-depth="3"] { display: none; }
            }
        }
    }
</style>