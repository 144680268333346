<template>
    <vue-modal :visible="isModalOpen" @on-close="closeRecycleModal" id="comment-recycle-modal">
        <template #header>
            <h5 class="modal-title"><span class="fas fa-trash mr-1 text-tertiary"></span>Confirm Comment Delete</h5>
        </template>

        <div class="d-flex flex-column align-items-center">
            <span class="fas fa-exclamation-triangle text-danger fa-7x"></span>
            <p class="h4 font-weight-bold">Are you sure you want to delete this comment?</p>
            <p>This action is permanent. Consider marking the comment as declined.</p>
        </div>

        <template #footer>
            <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-outline-primary cancel-btn" @click="closeRecycleModal">Cancel</button>
                <button type="button" class="btn btn-primary confirm-btn ml-2" @click="recycleComment" :disabled="isModalProcessing">{{ isModalProcessing ? 'Processing...' : 'Delete comment' }}</button>
            </div>
        </template>
    </vue-modal>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: 'RecycleCommentModal',
        computed: {
            ...mapState('comment', {
                activeRecord: state => state.addModifyComment.activeRecord,
                isModalOpen: state => state.recycleCommentModal.isModalOpen,
                isModalProcessing: state => state.recycleCommentModal.isModalProcessing,
            })
        },
        methods: {
            ...mapActions('comment', {
                closeModal: 'recycleCommentModal/closeModal',
                resetCommentModal: 'addModifyComment/resetCommentModal',
                recycleRecord: 'recycleCommentModal/recycleRecord',
            }),
            closeRecycleModal(){
                this.resetCommentModal();
                this.closeModal();
            },
            recycleComment(){
                this.recycleRecord({ ajaxRoute: '/vpanel/modules/blog/comment/destroy/'+this.activeRecord.id, recordId: this.activeRecord.id, dispatchAction: 'comment/list/removeRecordFromDataTable'});
                this.resetCommentModal();
            }
        }
    }
</script>