<template>
    <div class="header-template-1" :data-variant="variant">
        <div class="dynamic-container">
            <div class="top-bar" v-if="primaryLinks || secondaryLinks">
                <div class="primary-links" v-if="primaryLinks">
                    <vue-link
                        v-for="link in primaryLinks"
                        :key="link.text"
                        :link-obj="link"
                    ></vue-link>
                </div>
                <div class="secondary-links" v-if="secondaryLinks">
                    <vue-link
                        v-for="link in secondaryLinks"
                        :key="link.text"
                        :link-obj="link"
                    ></vue-link>
                </div>
            </div>
            <div class="bottom-bar">
                <div class="d-flex align-items-center">
                    <div class="logo">
                        <img :src="logoPath" alt="Company logo">
                        <vue-link link-path="/" link-text="Company name home" link-class="absolute-fill text-throw"></vue-link>
                    </div>
                    <vue-nav
                        v-bind="{...$props}"
                        :current-page-id="currentPageId"
                        :mobile-expanded="mobileNavExpanded"
                        @open-mobile-menu="openMobileMenu()"
                        @close-mobile-menu="closeMobileMenu()"
                    ></vue-nav>
                </div>
                <div class="d-flex align-items-center">
                    <div class="ctas" v-if="primaryCta || secondaryCta">
                        <vue-link v-if="primaryCta" :link-obj="primaryCta"></vue-link>
                        <vue-link v-if="secondaryCta" :link-obj="secondaryCta"></vue-link>
                    </div>
                    <vue-site-search
                        :search-template="searchTemplate"
                        v-bind="{...$props}"
                    ></vue-site-search>
                    <div class="mobile-controls">
                        <p class="open-mobile-menu" @click="openMobileMenu()">
                            <span class="fas fa-bars"></span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { themingMixin } from '../../../Mixins/themingMixin';
import { navMixin } from '../../../Mixins/navMixin';
import { headerMixin } from '../../../Mixins/headerMixin';

export default {
    mixins: [
        navMixin,
        headerMixin,
        themingMixin,
    ],
    props: [
        "primaryCta",
        "secondaryCta",
        "primaryLinks",
        "secondaryLinks",
    ],
}
</script>

<style lang="scss" scoped>
    @import '../../../../../../sass/vue-import';
    $theme-color: var(--theme-color, 255, 255, 255);
    $theme-complement: var(--theme-complement, 0, 0, 0);
    $theme-complement-compliant: var(--theme-complement-compliant, 255, 255, 255);

    .logo {
        position: relative;
        height: 100px;
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        img {
            max-width: 100%;
            max-height: 100%;
            vertical-align: bottom;
        }
    }

    .header-template-1 { background-color: rgb($theme-color); }

    .mobile-controls {
        @include desktop { display: none; }

        .open-mobile-menu {
            font-size: 24px;
            margin: 0 0 0 1rem;
        }
    }

    .top-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 -1rem;
        padding: .25rem 0;

        + .bottom-bar {
            position: relative;
            margin-top: 1px;
            border-top: 1px solid rgba($theme-complement, .2);
        }
    }

    .bottom-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0;
    }

    .dynamic-container {

        // Default container variant
        .header-template-1:not([data-variant]) & {
            max-width: var(--container-width, 1140px);
            margin-left: auto;
            margin-right: auto;
            padding-left: calc((var(--grid-gutter-width, 30px) / 2));
            padding-right: calc((var(--grid-gutter-width, 30px) / 2));
        }

        // Full width variant
        .header-template-1[data-variant="full"] & {
            padding-left: calc((var(--grid-gutter-width, 30px) / 2));
            padding-right: calc((var(--grid-gutter-width, 30px) / 2));
        }
    }

    .primary-links,
    .secondary-links {
        display: flex;

        a {
            color: rgb($theme-complement);
            padding: .25rem 1rem;
        }
    }
</style>