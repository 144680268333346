<template>
    <form @submit.prevent="updateCategories()" ref="form">
        <OsfwVendorCategorySelector
            :question-name="questionName"
            :toggle-placeholder="togglePlaceholder"
            :refetch-inc="refetchInc"
            @update-selected="updateSelected($event)"
            @modified-categories="modifiedCategories($event)"
            @user-logged-in="userLoggedIn = true"
        />
        <button
            type="submit"
            class="fb-save-filters"
            :class="{active: userLoggedIn && categoriesAreModified}"
        >
            <span class="fas fa-save"></span>
            <span class="text">Save Filters</span>
        </button>
        <div
            class="fb-supplementary-label"
            :class="{active: !questionName && showSupplementaryLabel}"
        >
            <span class="text">{{ togglePlaceholder || 'Save Filters'}}</span>
        </div>
        <div class="toast-message" :class="{active: showToast}"><span class="fas fa-check-circle"></span>{{ toastMessage }}</div>
    </form>
</template>

<script>
import OsfwVendorCategorySelector from '@v/Custom/Global/OsfwVendorCategorySelector.vue';

export default {
    name: "OsfwVendorCategorySelectorWithSave",
    emits: ['update-selected'],
    props: {
        questionName: {
            type: String,
            default: "What are you looking for?"
        },
        togglePlaceholder: {
            type: String,
            default: "Select all that apply..."
        },
    },
    components: {
        OsfwVendorCategorySelector
    },
    data() {
        return {
            userLoggedIn: 0,
            toastMessage: "",
            showToast: false,
            categoriesAreModified: false,
            refetchInc: 0,
            showSupplementaryLabel: false
        }
    },
    methods: {
        modifiedCategories(bool) {
            this.categoriesAreModified = bool;
        },
        updateCategories() {
            let vm = this;
            if (vm.userLoggedIn && vm.categoriesAreModified) {
                let formData = new FormData(vm.$refs.form);

                axios.post('/portal/users/update-cats', formData)
                .then(res => {
                    // Show success message for 5 seconds
                    vm.toastMessage = res.data.message;
                    setTimeout(() => vm.toastMessage = "", 5200);
                    vm.showToast = true;
                    setTimeout(() => vm.showToast = false, 5000);
                    // Refect source of truth
                    vm.refetchInc++;
                })
                .catch(err => console.error(err))
            }
        },
        updateSelected(categories) {
            this.showSupplementaryLabel = !!categories.length;
            this.$emit('update-selected', categories);
        },
    }
}
</script>

<style lang="scss" scoped>
form {
    position: relative;

    &::v-deep .fb-dropdown-toggle {
        min-height: 38px;
    }
}

.fb-save-filters {
    background-color: var(--success);
    color: white;
    right: 0;

    &:hover {
        padding-bottom: calc(.25rem + 2px);
    }
}

.fb-supplementary-label {
    left: 0;
}

.fb-supplementary-label,
.fb-save-filters {
    display: flex;
    align-items: center;
    gap: 4px;
    opacity: 0;
    transition: 200ms ease-out;
    font-size: 12px;
    pointer-events: none;
    visibility: hidden;
    position: absolute;
    top: 0;
    padding: .25rem;
    border: 0;
    border-radius: 4px 4px 0 0;
    line-height: 1;
    transform: translateY(calc(-100% + 4px));

    &.active {
        opacity: 1;
        pointer-events: all;
        visibility: visible;
        transform: translateY(-100%);
    }
}

.toast-message {
    position: fixed;
    top: 1rem;
    right: 1rem;
    padding: .5rem 1rem;
    font-weight: bold;
    border-radius: 3px;
    background-color: var(--success);
    color: white;
    transform: translateY(-10px);
    opacity: 0;
    visibility: hidden;
    transition: 200ms ease-out;
    pointer-events: none;
    display: flex;
    align-items: center;
    gap: .5rem;
    z-index: 10;

    &.active {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }
}
</style>