<template>
	<div class="datatable-section p-2">
		<div class="mb-2">
			<add-new-tag-button />
		</div>
        <tag-list />
		<add-modify-tag-modal />
		<recycle-tag-modal />
	</div>
</template>

<script>
    import { mapActions } from "vuex";
    export default {
        name: "TagIndex",
        created() {
            this.fetchTags();
            this.setCsrfToken();
        },
        methods: {
            ...mapActions("tag", {
                setCsrfToken: "setCsrfToken",
                fetchTags: "list/fetchTags",
            }),
        },
    };
</script>