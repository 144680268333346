<template>
    <div class="featured-blogs">
        <p class="section-title">Featured Blogs</p>
        <div
            :key="blog.id"
            v-for="blog in featured"
        >
            <a
                class="featured-blog"
                :href="blog.permalink"
                :title="`Internal link to the featured ${blog.title} post in the same window`"
            >
                <div class="blog-image">
                    <img :src="blog.thumbnail_image" :alt="blog.thumbnail_image_alt">
                </div>
                <div class="blog-details">
                    <p class="blog-date">
                        <span class="fas fa-calendar mr-1"></span>
                        {{ blog.scheduled_at }}
                    </p>
                    <p class="title">{{ blog.title }}</p>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'SiteBlogSidebarFeaturedList',
    computed: {
        ...mapState('blog', [
            'featured'
        ])
    }
}
</script>

<style lang="scss" scoped>
    .featured-blogs {
        .section-title {
            font-size: 24px;
            font-weight: bold;
        }

        .featured-blog {
            display: flex;
            margin-bottom: 1rem;
            text-decoration: none;

            .blog-image {
                width: 50px;
                margin-right: 1rem;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            .blog-date {
                margin: 0;
                font-size: 14px;

                svg { margin-right: .5rem; }
            }

            .title {
                margin: 0;
                font-size: 16px;
                font-weight: bold;
            }
        }
    }
</style>