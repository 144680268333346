export const headerMixin = {
    props: {
        navInfo: {
            type: Array,
        },
    },
    computed: {
        currentPageId() {
            return this.pageUuid;
        },
        headerTemplate() {
            return this.designConfig?.template?.header_template || 1;
        },
        navTemplate() {
            return "1"; // This will eventually become dynamic once we polish the rest of 3.0
        },
        searchTemplate() {
            return "1" // Same here
        }
    },
    methods: {
        addVars() {
            document.body.style.setProperty('--hd-height', this.$el.offsetHeight + 'px');
        }
    },
    mounted() {
        document.body.dataset.headerTemplate = this.headerTemplate;
        document.body.dataset.navTemplate = this.navTemplate;
        document.body.dataset.searchTemplate = this.searchTemplate;

        let vm = this;
        this.$nextTick(() => vm.addVars());
        window.addEventListener('resize', () => vm.addVars());
    },
    updated() {
        this.addVars();
    }
}

export default { headerMixin }