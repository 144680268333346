<template>
    <table id="datatable" class="table table-striped table-bordered dataTable dtr-inline" cellspacing="0" role="grid" aria-describedby="datatable_info" :nonce="cspNonce">
        <thead>
            <tr role="row">
                <th
                    class="table-head"
                    tabindex="0"
                    aria-controls="datatable"
                >Name</th>

                <th
                    class="table-head"
                    tabindex="0"
                    aria-controls="datatable"
                >Categories</th>

                <th
                    class="table-head"
                    tabindex="0"
                    aria-controls="datatable"
                >Publish Date</th>

                <th
                    class="table-head"
                    tabindex="0"
                    aria-controls="datatable"
                >Comments</th>

                <th
                    class="table-head"
                    tabindex="0"
                    aria-controls="datatable"
                >Featured</th>

                <th
                    class="table-head"
                    tabindex="0"
                    aria-controls="datatable"
                >Options</th>
            </tr>
        </thead>
    </table>
</template>

<script>
    import { mapState, mapActions, mapMutations } from 'vuex';

    export default {
        name: 'BlogList',
        created(){
            document.addEventListener('click', this.buttonEvent);
        },
        beforeDestroy() {
            document.removeEventListener('click', this.buttonEvent);
        },
        computed: {
            ...mapState('blog/list', {
                cspNonce: state => state.cspNonce,
                tableData: state => state.tableData
            }),
        },
        methods: {
            ...mapMutations('blog/addModifyBlog', [
                'SET_ACTIVE_RECORD'
            ]),
            ...mapActions('blog/addModifyBlog', [
                'setFormIsAdd'
            ]),
            ...mapActions('blog/recycleBlogModal', [
                'openModal'
            ]),
            buttonEvent(event) {
                if (event.target.closest('.datatable-options')) {
                    const blogId = event.target.closest('.datatable-options').dataset.id;

                    // find the selected record from the array of table data
                    let activeRecord = this.tableData.find(blog => blog.id == blogId);

                    // set active record in the store
                    this.SET_ACTIVE_RECORD(activeRecord);

                    // Recycle blog
                    if (event.target.classList.contains('recycle-blog')) {
                        event.preventDefault();
                        // open modal
                        this.openModal(null);
                    }

                    // Modify blog
                    if (event.target.classList.contains('modify-blog')) {
                        event.preventDefault();
                        // set form action to modify
                        this.setFormIsAdd(false);
                        this.$router.push({ name: 'BlogForm' });
                    }

                    if (event.target.classList.contains('comment-blog')) {
                        event.preventDefault();
                        this.$router.push({ name: 'Comments', params: { id: blogId } });
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .dataTable {
        &::v-deep {
            .categories {
                p, span {
                    font-size: 14px;
                    line-height: 1.25;
                }
            }
        }
    }
</style>