<template>
	<div class="datatable-section p-2">
		<div class="mb-2">
			<add-new-comment-button />
		</div>
        <comment-list />
		<add-modify-comment-modal :id="$route.params.id" />
		<recycle-comment-modal />
	</div>
</template>

<script>
    import { mapActions } from "vuex";

    export default {
        name: "CommentIndex",
        created() {
            if (this.$route.params.id) {
                this.fetchCommentsByPostId(this.$route.params.id);
            } else {
                this.fetchComments();
            }
            this.setCsrfToken();
        },
        methods: {
            ...mapActions("comment", {
                setCsrfToken: "setCsrfToken",
                fetchComments: "list/fetchComments",
                fetchCommentsByPostId : "list/fetchCommentsByPostId"
            })
        }
    };
</script>