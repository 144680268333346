<template>
    <div class="blog-search">
        <form class="search-form" @submit.prevent="setSearchModel()">
            <VueSearch name="Search" v-model="searchModel" />
            <button type="submit" class="btn btn-primary"><span class="fas fa-search"></span></button>
        </form>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: "SiteBlogSearch",
    data() {
        return {
            searchModel: '',
        }
    },
    computed: {
        ...mapState('blog', [
            'blogs'
        ])
    },
    methods: {
        setSearchModel() {
            this.blogs.searchModel = this.searchModel;
        },
        resetListAndModels() {
            this.sortModel = "1";
            this.blogs.resetAll();
        },
    },
    mounted() {
        this.searchModel = this.blogs.searchModel;
    },
    watch: {
        searchModel(n,o) {
            if (o && !n) {
                this.setSearchModel();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .blog-search {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        padding: .5rem;
        margin: -.5rem -.5rem 1rem;

        .search-form {
            display: flex;
            flex-grow: 9999;

            &::v-deep {
                .form-group {
                    margin: 0;
                }

                .btn {
                    border-top-right-radius: .25rem;
                    border-bottom-right-radius: .25rem;
                }

                input {
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;
                    border-right-width: 0;
                }
            }
        }

        .search-form,
        .form-group {
            flex-grow: 1;
            min-width: max-content;
        }
    }
</style>