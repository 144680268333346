<template>
	<div class="datatable-section p-2">
		<div class="mb-2">
			<add-new-category-button />
		</div>
        <category-list></category-list>
		<add-modify-category-modal />
		<category-recycle-modal />
	</div>
</template>

<script>
    import { mapActions } from "vuex";
    export default {
        name: "CategoryIndex",
        created() {
            this.fetchCategories();
            this.setCsrfToken();
        },
        methods: {
            ...mapActions("category", {
                setCsrfToken: "setCsrfToken",
                fetchCategories: "list/fetchCategories",
            }),
        },
    };
</script>