<template>
    <div class="site-config-nav vp-tab-nav">

        <router-link :to="{name:'Site Config'}" class="nav-link" title="Internal link that opens site config" exact>Site Config</router-link>
        <router-link :to="{name:'Design'}" class="nav-link" title="Internal link that opens site design config" exact>Design</router-link>
        <button class="nav-link" title="Edit robots.txt" @click.prevent="openRobotsModal">Robots</button>
        <button class="nav-link" title="Edit sitemap.xml" @click.prevent="openSitemapModal">Sitemap</button>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    methods: {
        ...mapActions('siteConfig', {
            openRobotsModal: "robots/openModal",
            openSitemapModal: "sitemap/openModal",
        })
    }
}
</script>