<template>
    <div class="vue-site-search-2">
        <div class="vss-input-group form-group">
            <input
                class="form-control"
                type="search"
                placeholder="Search"
                ref="siteSearch"
                v-model="siteSearch"
            />
            <a
                class="search-icon"
                :href="`/search?${encodeURIComponent(siteSearch)}`"
                :title="`Search site for ${siteSearch}`"
            ><span class="fas fa-search"></span></a>
        </div>
    </div>
</template>

<script>
import { themingMixin } from '../../../Mixins/themingMixin';

export default {
    mixins: [
        themingMixin
    ],
    data() {
        return {
            siteSearch: '',
        }
    },
}
</script>

<style lang="scss" scoped>
    @import '../../../../../../sass/vue-import';
    $theme-color: var(--theme-color, 255, 255, 255);
    $theme-complement: var(--theme-complement, 0, 0, 0);
    $theme-complement-compliant: var(--theme-complement-compliant, 255, 255, 255);

    .vue-site-search-2 {
        display: flex;
        align-items: center;

        .vss-input-group {
            margin: 0;
            position: relative;
            height: 100%;

            input[type="search"] {
                padding-right: 40px;
                height: 100%;
                font-size: 18px;
            }

            .search-icon {
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

</style>