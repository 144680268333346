<template>
    <form id="add-modify-category-form" class="category-form needs-validation" name="add-modify-category-form" novalidate @submit.prevent="submitForm">
        <div class="form-errors"></div>
        <input type="hidden" name="_token" :value="activeRecord._token" />
        <input type="hidden" name="id" :value="activeRecord.id" v-if="!isActionAdd" />
        <div class="d-flex flex-column justify-content-between align-items-stretch h-100">
            <div class="document-form-fields d-flex flex-column align-items-stretch">
                <h5>Basic Information</h5>
                    <div class="form-group has-label">
                        <div class="d-flex justify-content-between align-items-center">
                            <label class="required" for="full_name">Name</label>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="The  name of the category"
                            ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <input
                            type="text"
                            class="form-control"
                            required 
                            name="name"
                            id="name"
                            title="Please enter category name"
                            autocomplete="off"
                            data-invalid-feedback="Please enter a category name."
                            v-model="activeRecord.name"
                        />
                        <div class="invalid-feedback">
                            Please enter a name.
                        </div>
                    </div> 

                    <div class="form-group">
                        <div class="radio-legend d-flex justify-content-between align-items-center">
                            <span class="required mr-3">Status</span>
                            <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="right"
                                data-boundary="window"
                                title="Please select a status for this category."
                            ><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                        </div>
                        <fieldset>
                            <legend class="label sr-only">Status</legend>
                            <div class="form-check form-check-inline form-check-radio">
                                <label class="form-check-label" for="status-active">
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        title="status"
                                        required
                                        checked
                                        name="status"
                                        id="status-active"
                                        value="1"
                                        v-model="activeRecord.is_active"
                                      
                                        data-invalid-feedback="Please select a status for this category."
                                    />
                                    <span class="form-check-sign"></span>
                                    Active
                                </label>
                            </div>
                            <div class="form-check form-check-inline form-check-radio">
                                <label class="form-check-label" for="status-inactive">
                                    <input
                                        class="form-check-input"
                                        title="status"
                                        type="radio"
                                        name="status"
                                        id="status-inactive"
                                        value="0"
                                        v-model="activeRecord.is_active"
                                        data-invalid-feedback="Please select a status for this category."
                                    />
                                    <span class="form-check-sign"></span>
                                    Inactive
                                    <div class="invalid-feedback">
                                    Please select a status for this tag.
                                    </div>
                                </label>
                            </div>
                        </fieldset>
                    </div>
            </div>
        </div>
    </form>
</template>
<script>
 import { mapState, mapActions } from 'vuex';
    export default {
        
        name: 'AddModifyCategoryForm',
        props: [
          
            'isActionAdd',
            'activeRecord',

        ],
        data() {
            return {
                image:''
            }
        },

        computed: {

              ...mapState('category/list', {
               
                moduleConfig: state =>state.moduleConfig,
                cspNonce: state => state.cspNonce,

            }),

            ...mapState('blog/list', {
               
                posts: state =>state.tableData,
               
            }),

        
       },
        methods: {
            submitForm(){
                this.$emit('submitForm');
               
            },
            closeModal(){
                this.$emit('close-category-modal');
            },
           
        
            }
          
    }
</script>