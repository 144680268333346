<template>
    <div class="filters">
        <div class="tabs">
            <button
                class="tab"
                :class="{active:activeFilterTab=='categories'}"
                @click="setFilterTab('categories')"
            >Categories</button>
            <button
                class="tab"
                :class="{active:activeFilterTab=='recent'}"
                @click="setFilterTab('recent')"
            >Recent</button>
            <button
                class="tab"
                :class="{active:activeFilterTab=='archives'}"
                @click="setFilterTab('archives')"
            >Archives</button>
        </div>

        <div
            class="filter-section"
            :class="{active:activeFilterTab=='categories'}"
        >
            <button
                class="category"
                :class="{active: activeFilterType == 'categories' && activeFilterId == id}"
                v-for="(category, id) in categories"
                :key="'catFilter_' + id"
                @click="filterBlogs({type: 'categories', id: id})"
            >{{ category.name }}</button>
        </div>

        <div
            class="filter-section"
            :class="{active:activeFilterTab=='recent'}"
        >
            <button
                class="recent"
                v-for="blogId in recent"
                :key="'recent_' + blogId"
                @click="openBlogDetails(blogs[blogId].permalink)"
            >{{ blogs[blogId].title }}</button>
        </div>

        <div
            class="filter-section"
            :class="{active:activeFilterTab=='archives'}"
        >
            <button
                class="archive"
                :class="{active: activeFilterType == 'archives' && activeFilterId == id}"
                v-for="(archive, id) in archives"
                :key="'archived_' + id"
                @click="filterBlogs({type: 'archives', id: id})"
            >{{ archive.name }}</button>
        </div>

        <div
            class="filter-section"
            :class="{active:activeFilterTab===null}"
        >
            <p class="mt-2 text-gray text-14">Choose a tab above to view blog categories, recent, or archived blogs.</p>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'FrontendBlogSidebarFilters',
    computed: {
        ...mapState('blog', [
            'blogs',
            'activeFilterTab',
            'activeFilterType',
            'activeFilterId',
            'categories',
            'recent',
            'archives'
        ]),
    },
    methods: {
        ...mapActions('blog', [
            'openBlogDetails',
            'closeBlogDetails',
            'filterBlogs',
            'setFilterTab'
        ])
    }
}
</script>

<style lang="scss" scoped>
    .filters {
        margin-top: 1.5rem;

        .tabs {
            display: flex;
            flex-wrap: wrap;

            .tab {
                flex: 1 1 auto;
                background-color: hsl(0deg,0%,95%);
                border: 0;
                border-bottom: 2px solid hsl(0deg,0%,90%);
                outline: 0;
                padding: .25rem .5rem;
                transition: 200ms ease-out;

                &:hover:not(.active) {
                    background-color: hsl(0deg,0%,85%);
                    border-bottom: 2px solid hsl(0deg,0%,80%);
                }

                &.active {
                    border-bottom: 2px solid rgb(var(--blog-theme-primary));
                    background-color: rgba(var(--blog-theme-primary), .8);
                    color: rgb(var(--blog-theme-primary-compliant));
                }
            }
        }

        .filter-section {
            display: none;

            &.active {
                display: block;
            }

            button {
                display: block;
                width: 100%;
                text-align: left;
                background: white;
                border: solid hsl(0deg,0%,95%);
                border-width: 0 0 1px 0;
                outline: 0;
                padding: .5rem;
                font-size: 14px;
                font-weight: bold;
                transition: 200ms ease-out;

                &:hover {
                    background: hsl(0deg,0%,95%);
                }

                &.active {
                    background: hsl(0deg,0%,85%);
                    border-width: 0;
                }
            }
        }
    }
</style>