<template>
	<div class="vp-tab-nav">
		<router-link
			:to="{ name: 'BlogList' }"
			class="nav-link"
			active-class="current"
			title="Internal link that opens the blog page"
			exact
        >Blogs</router-link>

		<router-link
			:to="{ name: 'Comments' }"
			class="nav-link"
			active-class="current"
			title="Internal link that opens the comments page"
			exact
        >Comments</router-link>

		<router-link
			:to="{ name: 'Tags' }"
			class="nav-link"
			active-class="current"
			title="Internal link that opens the tags page"
			exact
        >Tags</router-link>

		<router-link
			:to="{ name: 'Categories' }"
			class="nav-link"
			active-class="current"
			title="Internal link that opens the categories page"
			exact
        >Categories</router-link>
	</div>
</template>

<script>
export default {
	name: "BlogSecondaryNav",
};
</script>