<template>
    <button type="button" class="btn btn-primary m-0" @click.prevent="openModal">Add Category</button>
</template>
<script>
    import { mapActions } from 'vuex';
    export default {
        name: 'AddNewCategoryButton',
        methods: {
            ...mapActions('category/addModifyCategory', [
               'openModal',
                'closeModal'
            ])
        }
    }
</script>