<template>
    <component
        v-if="template !== 'hidden'"
        :is="templateComponent"
        v-bind="{...$props,...$attrs}"
    ></component>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        designConfig: {
            type: Object,
        },
        pageUuid: {
            type: String,
            required: true,
        },
        navInfo: {
            type: Array,
            required: true,
        }
    },
    computed: {
        template() {
            return this.designConfig?.template?.header_template || 1;
        },
        templateComponent() {
            let prefix = 'VueHeaderTemplate',
                check  = Object.keys(Object.getPrototypeOf(this.$options.components)).includes(prefix + this.template);

            return prefix + ( check ? this.template : 1);
        },
    }
}
</script>

<style lang="scss" scoped>
    #vue-header {
        z-index: 100;
    }
</style>