<template>
    <div id="blog-module">
        <div class="blog-layout">
            <div class="blog-left">
                <FrontendBlogSearch />
                <template v-if="!isLoading">
                    <FrontendBlogDetails v-if="activeBlog"/>
                    <FrontendBlogList v-else/>
                </template>
                <vue-loader v-else></vue-loader>
            </div>

            <div class="blog-sidebar">
                <!-- For when anything more than the author name is implemented -->
                <!-- <FrontendBlogSidebarAuthorBlurb v-if="activeBlog"/> -->
                <FrontendBlogSidebarFeaturedList />
                <FrontendBlogSidebarFilters />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
    name: 'SiteModuleIndex',
    props: ["redirectUrl"],
    computed: {
        ...mapState('blog', [
            'blogs',
            'activeFilterType',
            'activeFilterId',
            'activeBlog',
            'isLoading',
            'initialTitle',
            'initialDescription',
            'initialKeywords',
        ]),
        ...mapGetters('blog', [
            'totalItems',
        ])
    },
    methods: {
        ...mapActions('blog', [
            'fetchBlogs',
            'openBlogDetails',
            'filterBlogs',
            'setInitialMetaInfo'
        ]),
        updateMetas(title, description, keywords) {
            let obj = { 'Title': title, 'Description': description, 'Keywords': keywords }
            Object.keys(obj).forEach((key) => {
                let isTitle = key == 'Title';
                let tag = document.querySelector(isTitle ? 'head title' : `head meta[name="${key}"]`);
                if (tag) {
                    tag[isTitle ? 'innerHTML' : 'content'] = obj[key];
                } else {
                    let newTag = document.createElement(isTitle ? 'title' : 'meta');
                    if (!isTitle) newTag.name = key
                    newTag[isTitle ? 'innerHTML' : 'content'] = obj[key];
                    document.head.appendChild(newTag);
                }
            })
        },
        updateUrl(blogId, type, id) {
            let url = '/blog';
            if (blogId) {
                let blog = this.blogs[blogId];
                url = `/blog/${blog.permalink}`;
                this.updateMetas(blog.meta_title, blog.meta_description, blog.meta_keywords);
            } else {
                if (type && id) {
                    let corrected = { archives: 'archive', categories: 'category', tags: 'tag' };
                    url = `/blog/${corrected[type]}/${id}`;
                }

                this.updateMetas(this.initialTitle, this.initialDescription, this.initialKeywords);
            }
            window.history.replaceState(null,null,url);
        }
    },
    watch: {
        activeBlog: function(n, o) {
            this.updateUrl(n, this.activeFilterType, this.activeFilterId)
        },
        activeFilterType: function(n, o) {
            this.updateUrl(this.activeBlog, n, this.activeFilterId);
        },
        activeFilterId: function(n, o) {
            this.updateUrl(this.activeBlog, this.activeFilterType, n);
        }
    },
    created() {
        this.setInitialMetaInfo();
        if (!this.redirectUrl) this.fetchBlogs();

        if (this.redirectUrl) {
            this.fetchBlogs().then(() => {
                let key,
                    value,
                    url = new URL(this.redirectUrl),
                    path = url.pathname.split('/').slice(2);

                key = path[0]
                if (path.length > 1) value = path[1];

                if (key == 'category') this.filterBlogs({type: 'categories', id: value})
                else if (key == 'tag') this.filterBlogs({type: 'tags', id: value})
                else if (key == 'archive') this.filterBlogs({type: 'archives', id: value})
                else if (key) this.openBlogDetails(key)
            });
        }
    }
};
</script>

<style lang="scss" scoped>
    @use 'sass:math';
    @import '../../../../../../sass/_vue-import.scss';

    .blog-layout {
        display: flex;
        margin: 1rem -1rem;

        @include desktop {
            .blog-left { flex: 1 1 math.div(200%, 3); }
            .blog-sidebar {
                max-width: 350px;
                flex: 1 1 math.div(100%, 3);
            }
        }

        @include not-desktop {
            flex-direction: column;
        }

        &::v-deep .disabled {
            opacity: .4;
            pointer-events: none;
        }

        .blog-left, .blog-sidebar { margin: 1rem; }
    }
</style>

<style>
    #at-custom-sidebar { display: none; }
</style>