<template>
    <div class="featured-blogs">
        <p class="section-title">Featured Blogs</p>
        <div
            class="featured-blog"
            v-for="id in featured"
            :key="id"
            @click="openBlogDetails(blogs[id].permalink)"
        >
            <div class="blog-image">
                <img :src="blogs[id].thumbnail_image" :alt="blogs[id].thumbnail_image_alt">
            </div>
            <div class="blog-details">
                <p class="blog-date" v-html="formattedDate(blogs[id])"></p>
                <p class="title">{{ blogs[id].title }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';

export default {
    name: 'FrontendBlogSidebarFeaturedList',
    computed: {
        ...mapState('blog', [
            'blogs',
            'featured'
        ]),
    },
    methods: {
        ...mapActions('blog', [
            'openBlogDetails'
        ]),
        formattedDate(blog) {
            let date = dayjs(blog.scheduled_at).format("MMMM D, YYYY");
            return `<span class="fas fa-calendar mr-1"></span>${date}`;
        }
    },
}
</script>

<style lang="scss" scoped>
    .featured-blogs {
        .section-title {
            font-size: 24px;
            font-weight: bold;
        }

        .featured-blog {
            display: flex;
            margin-bottom: 1rem;
            cursor: pointer;

            .blog-image {
                width: 50px;
                margin-right: 1rem;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            .blog-date {
                margin: 0;
                font-size: 14px;

                svg { margin-right: .5rem; }
            }

            .title {
                margin: 0;
                font-size: 16px;
                font-weight: bold;
            }
        }
    }
</style>