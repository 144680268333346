<template>
    <div class="site-design-section">
        <div class="left">
            <div class="mb-1 d-flex flex-row justify-content-between">
                <div class="site-config-section-header d-flex flex-column">
                    <p>From this screen you can modify things like the site's theme, adjust your logo, or even change layouts like the header. Click on the tabs above to edit robots.txt or sitemap.xml.</p>
                </div>

                <div class="site-config-section-button d-flex flex-column justify-content-end mb-1">
                    <button class="btn btn-primary" @click="updateDesignOptions">{{ isFormProcessing ? 'Processing...' : 'Update Site Design' }}</button>
                </div>
            </div>

            <site-design-list />
        </div>
        <div class="right">
            <site-design-preview :design-config="designConfig" />
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
    name: 'SiteDesignLayout',
    computed: {
        ...mapState('siteConfig/design', {
            designConfig: state => state.designOptions,
            designOptions: state => state.designOptions.theme,
            isFormProcessing: state => state.isFormProcessing
        })
    },
    methods: {
        ...mapActions('siteConfig/design', [
            'updateDesignOptions'
        ])
    }
}
</script>

<style lang="scss" scoped>
    button.nav-link {
        border-left: 0;
        border-top: 0;
        border-bottom: 0;
    }

    .site-design-section {
        padding: 2rem;
        display: flex;

        .left {
            flex: 0 0 75%;
        }

        .right {
            flex: 0 0 25%;
            padding: 0 2rem;
        }
    }
</style>