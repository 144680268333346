<template>
    <div class="site-config-table">
        <div class="sct-header">
            <p class="sct-name">Name</p>
            <p class="sct-value">Value</p>
            <p class="sct-description">Description</p>
        </div>
        <div class="sct-body">
            <div class="sct-row">
                <p class="sct-name"><label for="primary_color">Primary Color</label></p>
                <div class="sct-value pr-2 sctv-color">
                    <input
                        type="color"
                        id="primary_color"
                        name="Primary Color"
                        title="Edit Primary Color"
                        :value="designOptions.primary_color"
                        @input="updateValue('primary_color')"
                        class="form-control site-config-input"
                    />
                </div>
                <p class="sct-description">Your Primary Color</p>
            </div>
            <div class="sct-row">
                <p class="sct-name"><label for="secondary_color">Secondary Color</label></p>
                <div class="sct-value pr-2 sctv-color">
                    <input
                        type="color"
                        id="secondary_color"
                        name="Secondary Color"
                        title="Edit Secondary Color"
                        :value="designOptions.secondary_color"
                        @input="updateValue('secondary_color')"
                        class="form-control site-config-input"
                    />
                </div>
                <p class="sct-description">Your Secondary Color</p>
            </div>
            <div class="sct-row">
                <p class="sct-name"><label for="display_font_config">Display Font</label></p>
                <div class="sct-value pr-2 sctv-select">
                    <select
                        class="form-control site-config-input"
                        title="Edit Display Font"
                        id="display_font_config"
                        name="Display Font"
                        @change="updateValue('display_font')"
                    >
                        <option :selected="designOptions.display_font == ``" value="" disabled selected hidden></option>
                        <option :selected="designOptions.display_font == `'Roboto', sans-serif`" value="'Roboto', sans-serif">Roboto</option>
                        <option :selected="designOptions.display_font == `'Poppins', sans-serif`" value="'Poppins', sans-serif">Poppins</option>
                        <option :selected="designOptions.display_font == `'Playfair Display', serif`" value="'Playfair Display', serif">Playfair Display</option>
                        <option :selected="designOptions.display_font == `'Quicksand', sans-serif`" value="'Quicksand', sans-serif">Quicksand</option>
                        <option :selected="designOptions.display_font == `'Josefin Slab', serif`" value="'Josefin Slab', serif">Josefin Slab</option>
                    </select>
                </div>
                <p class="sct-description">Your display/heading font</p>
            </div>
            <div class="sct-row">
                <p class="sct-name"><label for="body_font_config">Body Font</label></p>
                <div class="sct-value pr-2 sctv-select">
                    <select
                        name="Body Font"
                        id="body_font_config"
                        class="form-control site-config-input"
                        title="Choose from suggested Body Font pairings"
                        @change="updateValue('body_font')"
                    >
                        <option :selected="designOptions.body_font == ``" value="" disabled selected hidden></option>
                        <template v-if="designOptions.display_font == `'Roboto', sans-serif`">
                            <option :selected="designOptions.body_font == `'Open Sans', sans-serif`" value="'Open Sans', sans-serif">Open Sans</option>
                            <option :selected="designOptions.body_font == `'Noto Sans JP', sans-serif`" value="'Noto Sans JP', sans-serif">Noto Sans JP</option>
                            <option :selected="designOptions.body_font == `'Lato', sans-serif`" value="'Lato', sans-serif">Lato</option>
                        </template>

                        <template v-if="designOptions.display_font == `'Poppins', sans-serif`">
                            <option :selected="designOptions.body_font == `'Open Sans', sans-serif`" value="'Open Sans', sans-serif">Open Sans</option>
                            <option :selected="designOptions.body_font == `'Roboto', sans-serif`" value="'Roboto', sans-serif">Roboto</option>
                            <option :selected="designOptions.body_font == `'Lora', serif`" value="'Lora', serif">Lora</option>
                        </template>

                        <template v-if="designOptions.display_font == `'Playfair Display', serif`">
                            <option :selected="designOptions.body_font == `'Roboto', sans-serif`" value="'Roboto', sans-serif">Roboto</option>
                            <option :selected="designOptions.body_font == `'Open Sans', sans-serif`" value="'Open Sans', sans-serif">Open Sans</option>
                            <option :selected="designOptions.body_font == `'Montserrat', sans-serif`" value="'Montserrat', sans-serif">Montserrat</option>
                        </template>

                        <template v-if="designOptions.display_font == `'Quicksand', sans-serif`">
                            <option :selected="designOptions.body_font == `'Roboto', sans-serif`" value="'Roboto', sans-serif">Roboto</option>
                            <option :selected="designOptions.body_font == `'Open Sans', sans-serif`" value="'Open Sans', sans-serif">Open Sans</option>
                            <option :selected="designOptions.body_font == `'Montserrat', sans-serif`" value="'Montserrat', sans-serif">Montserrat</option>
                        </template>

                        <template v-if="designOptions.display_font == `'Josefin Slab', serif`">
                            <option :selected="designOptions.body_font == `'Roboto', sans-serif`" value="'Roboto', sans-serif">Roboto</option>
                            <option :selected="designOptions.body_font == `'Open Sans', sans-serif`" value="'Open Sans', sans-serif">Open Sans</option>
                            <option :selected="designOptions.body_font == `'Montserrat', sans-serif`" value="'Montserrat', sans-serif">Montserrat</option>
                        </template>
                    </select>
                </div>
                <p class="sct-description">Popular font pairings based on your chosen display font</p>
            </div>
            <div class="sct-row">
                <p class="sct-name"><label for="logo">Logo</label></p>
                <div class="sct-value pr-2 sctv-file">
                    <input
                        type="text"
                        disabled
                        id="logo"
                        name="Logo"
                        title="Edit Logo"
                        @input="updateValue('logo')"
                        :value="designOptions.logo"
                        class="form-control site-config-input"
                    />

                    <button
                        id="logo_choose"
                        data-input="logo"
                        class="btn btn-tertiary"
                        @click.prevent="openFileManager"
                        title="Opens the file manager modal where you can choose to upload a new file or choose from your file library"
                    >Choose File</button>

                    <button
                        id="logo_remove"
                        data-input="logo"
                        @click.prevent="deleteLogo"
                        title="Clears the current file"
                        class="btn btn-outline-primary"
                    >Delete file</button>

                    <a
                        target="_blank"
                        class="btn btn-primary"
                        v-if="designOptions.logo"
                        :href="designOptions.logo"
                        title="`Opens ${config.value} in a new window`"
                    >Preview file</a>
                </div>
                <p class="sct-description">Your logo</p>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex';
    import { openFileManager } from '../../../../../../js/core/vpanel/vpanel-functions';

    export default {
        name: 'SiteDesignList',
        computed: {
            ...mapState('siteConfig/design', {
                designOptions: state => state.designOptions.theme,
            })
        },
        methods: {
            ...mapMutations('siteConfig/design', [
                'SET_VALUE_IN_DESIGN_OPTIONS'
            ]),
            openFileManager() {
                openFileManager(event.target, null);
            },
            deleteLogo() {
                this.designOptions.logo = '';
                document.querySelector(`#${event.target.dataset.input}`).value = '';
            },
            updateValue(prop) {
                this.SET_VALUE_IN_DESIGN_OPTIONS([prop, event.target.value]);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .sctv-file {
        display: flex;
        flex-wrap: wrap;
        margin: -.25rem;

        > * {
            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: auto;
            margin: .25rem;
            width: auto;
        }
    }
</style>