<template>
    <div id="site-config-panel">
        <site-config-nav></site-config-nav>

        <router-view :key="$route.fullPath" />

        <site-config-robots-modal :is-modal-open="showRobotsModal"/>

        <site-config-sitemap-modal :is-modal-open="showSitemapModal"/>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
    name: 'SiteConfigIndex',
    computed: {
        ...mapState('siteConfig', {
            showRobotsModal: state => state.robots.isModalOpen,
            showSitemapModal: state => state.sitemap.isModalOpen,
        })
    }
}
</script>

<style lang="scss" scoped>
    button.nav-link {
        border-left: 0;
        border-top: 0;
        border-bottom: 0;
    }
</style>