<template>
    <div class="blog-search">
        <vue-search
            @search="onSearch($event)"
            name="Search for a blog"
            v-model="searchState"
            incremental
        />
        <p class="assistive-text">Searches currently listed blogs</p>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: "FrontendBlogSearch",
    data() {
        return {
            searchState: ''
        }
    },
    computed: {
        ...mapState('blog', [
            'searchFilter'
        ])
    },
    watch: {
        searchFilter: function (n, o) {
            if (this.searchState != n) this.searchState = n;
        }
    },
    methods: {
        ...mapActions('blog',[
            'setSearchFilter'
        ]),
        onSearch(event) {
            this.setSearchFilter(event.target.value);
        }
    },
    mounted() {
        this.searchState = this.searchFilter || '';
    }
}
</script>

<style lang="scss" scoped>
    .blog-search::v-deep .form-group {
        margin-bottom: .5rem;
    }

    .assistive-text {
        font-size: 12px;
        color: var(--gray, #767676);
        line-height: 1.2;
        margin-bottom: 1.5rem;
    }
</style>