<template>
    <div class="blog-author-blurb">
        <!-- For when author image is implemented -->
        <!-- <div class="author-image">
            <div class="image-wrapper">
                <img src="/images/core/vpanel/bg-vpanel-login3.jpg" alt="Image alt text" />
            </div>
        </div> -->

        <p class="author-name">Author Name</p>
        <!-- For when author title is implemented -->
        <!-- <p class="author-title">Author Title</p> -->

        <!-- For when author bio is implemented -->
        <!-- <p class="author-bio-measure" ref="bioMeasure">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
        <p class="author-bio" :class="{expanded:bioExpanded}" ref="bio">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>

        <template v-if="bioOverflow">
            <button v-show="bioExpanded" @click="bioExpanded = false">See Less <span class="fas fa-chevron-up"></span></button>
            <button v-show="!bioExpanded" @click="bioExpanded = true">See More <span class="fas fa-chevron-down"></span></button>
        </template> -->

        <!-- For when author social links are implemented -->
        <!-- <div class="social-links">
            <vue-link link-icon="fab fa-facebook" link-path="/" link-title-name="our facebook page"></vue-link>
            <vue-link link-icon="fab fa-twitter" link-path="/" link-title-name="our twitter page"></vue-link>
            <vue-link link-icon="fab fa-linkedin" link-path="/" link-title-name="our linkedin page"></vue-link>
            <vue-link link-icon="fab fa-instagram" link-path="/" link-title-name="our instagram page"></vue-link>
            <vue-link link-icon="fab fa-pinterest" link-path="/" link-title-name="our pinterest page"></vue-link>
            <vue-link link-icon="fab fa-youtube" link-path="/" link-title-name="our youtube page"></vue-link>
            <vue-link link-icon="fab fa-vimeo" link-path="/" link-title-name="our vimeo page"></vue-link>
        </div> -->
    </div>
</template>

<script>
export default {
    // For when author bio is implemented
    // data() {
    //     return {
    //         bioExpanded: false,
    //         bioOverflow: true
    //     }
    // },
    // mounted() {
    //     this.checkBioOverflow();
    //     window.addEventListener('resize', this.checkBioOverflow);
    // },
    // beforeDestroy() {
    //     window.removeEventListener('resize', this.checkBioOverflow);
    // },
    // methods: {
    //     checkBioOverflow() {
    //         this.bioOverflow = this.$refs.bio.offsetHeight != this.$refs.bioMeasure.offsetHeight;
    //     }
    // }
}
</script>

<style lang="scss" scoped>
    @import '../../../../../../sass/_vue-import.scss';

    .blog-author-blurb {
        font-size: 14px;
        background-color: hsl(0deg,0%,95%);
        padding: 1rem;
        margin-bottom: 1.5rem;
        text-align: center;
        position: relative;

        button {
            background: none;
            outline: none;
            border: none;
            padding: 0;
            font-weight: bold;
            margin-top: 1rem;

            svg { margin-left: .5rem; }
        }
    }

    .author-image {
        padding: 1rem;

        .image-wrapper {
            position: relative;
            height: 0;
            width: 100%;
            padding-top: 100%;
            border-radius: 50%;
            overflow: hidden;

            img {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }

    .author-name {
        font-size: 24px;
        font-weight: bold;
        margin: 0;
    }

    .author-bio-measure {
        position: absolute;
        margin: 0;
        width: calc(100% - 2rem);
        pointer-events: none;
        visibility: hidden;
        opacity: 0;
        user-select: none;
    }

    .author-bio {
        @include truncate(14px,1.5,3);

        &.expanded {
            -webkit-line-clamp: inherit;
            max-height: 400px;
        }
    }

    .social-links {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: .75rem -.25rem 0;

        .vue-link {
            font-size: 16px;
            line-height: 1;
            margin: .25rem;
        }
    }
</style>