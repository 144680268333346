<template>
    <vue-modal :visible="isModalOpen" @on-close="closeCommentAddModifyModal" id="comment-add-modify-modal">
        <template #header>
            <h5 class="modal-title"><span class="fas fa-comment mr-1 text-tertiary"></span>&nbsp;{{ getModalHeader }}</h5>
        </template>

        <div class="page-settings-details pb-3 pt-0" v-if="!isActionAdd">
            <p class="psd-published">Published Date: <span>{{ formatDate(activeRecord.created_at) }}</span></p>
            <p class="psd-modified">Last Modified: <span>{{ formatDate(activeRecord.updated_at) }}</span></p>
            <p class="psd-status">Status: <span>{{ activeRecord.status }}</span></p>
        </div>

        <!-- Form Include -->
        <add-modify-Comment-form
            :class="{'d-none': isFormProcessing}"
            :is-action-add="isActionAdd"
            :id="id"
            :active-record="activeRecord"
            @submitForm="submitForm"
            @closeModal="closeModal"
        />

        <vpanel-loader v-if="isFormProcessing" />

        <template #footer>
            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-outline-primary cancel-button" @click.prevent="closeModal">Cancel</button>
                <button type="submit" class="btn btn-primary ml-1" :disabled="isFormProcessing" form="add-modify-comment-form">{{ isFormProcessing ? 'Processing...' : 'Apply' }}</button>
            </div>
        </template>

    </vue-modal>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import dayjs from 'dayjs';

    export default {
        name: 'AddModifyCommentModal',
        props:['id'],
        computed: {
            ...mapState('comment/addModifyComment', {
                isModalOpen: state => state.isModalOpen,
                activeRecord: state => state.activeRecord,
                isActionAdd: state => state.formActionIsAdd,
                isFormProcessing: state => state.isFormProcessing,
            }),
            getModalHeader() {
                let action = this.isActionAdd ? 'Add a New ' : 'Modify ';
                return action + 'Comment';
            }
        },
        methods: {
            ...mapActions('comment/addModifyComment', [
                'submitForm',
                'closeModal',
                'resetCommentModal'
            ]),
            closeCommentAddModifyModal(){
                let vm = this;
                vm.closeModal();
                setTimeout(function() {
                    vm.resetCommentModal()
                }, 200);
            },
            formatDate(date) {
                return dayjs(date).format('MMMM D, YYYY');
            }
        }
    }
</script>