<template>
    <div class="fb-fieldset fb-dropdown" role="group" :aria-labelledby="`question-vendor-category-label`" @blur="blurDropdown()">
        <p class="fb-legend radio-legend" :id="`question-vendor-category-label`" @click="toggleDropdown()" v-show="questionName">{{ questionName || 'Filter by Vendor Category' }}</p>

        <div class="fb-options" :class="{expanded: expanded}" ref="options">
            <div class="fb-fieldset-dropdown">
                <button
                    type="button"
                    class="fb-dropdown-toggle form-control"
                    :title="`Shows/Hides vendor categories`"
                    tabindex="-1"
                    @click.prevent="toggleDropdown()"
                    v-html="selectedNames || togglePlaceholder"
                ></button>
                <div class="fb-dropdown-container" ref="dropdown">
                    <label class="fb-option">
                        <input type="checkbox" @change="selectAll()" />
                        Select All
                    </label>
                    <label class="fb-option" v-for="option in options" :key="`option_${option}`" >
                        <input
                            class="`fb-control-checkbox"
                            type="checkbox"
                            name="vendor_states[]"
                            :value="option"
                            :data-selected-value="option"
                            @change="toggleIfTrigger()"
                            @focus="focusDropdown()"
                        />
                        {{ option }}
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "OsfwVendorStateSelector",
    emits: ['update-selected', 'modified-categories', 'user-logged-in'],
    props: {
        options: {
            type: Array,
            required: true
        },
        questionName: {
            type: String,
            default: ""
        },
        togglePlaceholder: {
            type: String,
            default: "Filter by State"
        },
        userId: {
            type: Number
        },
        refetchInc: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            expanded: false,
            computedInc: 0,
            mounted: false,
            states: [],
            userLoggedIn: 0,
        }
    },
    computed: {
        selectedOptions() {
            let c = this.computedInc; // fake dep to refresh
            if (!this.mounted) return [];
            let checked = Array.from(this.$refs.dropdown.querySelectorAll('input[name]:checked'));
            return checked.map(i => i.dataset.selectedValue);
        },
        selectedNames() {
            let res = '';
            if (this.selectedOptions.length) {
                let items = this.selectedOptions.map(n => {
                    let span = document.createElement('span');
                    span.textContent = n;
                    return span.outerHTML;
                });

                if (this.selectedOptions.length > 6) {
                    let additionalItem = document.createElement('span');
                    additionalItem.textContent = `+${this.selectedOptions.length - 6} Others`;
                    items = items.slice(0, 5);
                    items.push(additionalItem.outerHTML);
                }

                res = items.join("");
            }
            return res;
        },
        modifiedCategories() {
            let c = this.computedInc; // fake dep to refresh
            let originallySelected = this.categories.filter(c => c.current_user_selected).map(c => c.id);
            let currentlySelected  = Array.from(this.$refs.dropdown.querySelectorAll('input[name]:checked')).map(o => Number(o.value));
            let modified = !this.compareCategoryIds(originallySelected, currentlySelected);
            return modified
        }
    },
    methods: {
        compareCategoryIds(a, b) {
            // Sort a and b
            [a,b] = [[...a],[...b]].map(c => c.sort((d,e) => d - e));

            // Guard clauses
            if (a === b) return true;
            if (a == null || b == null) return false;
            if (a.length !== b.length) return false;

            // Check indexes
            for (var i = 0; i < a.length; ++i) {
                if (a[i] !== b[i]) return false;
            }

            return true;
        },
        toggleDropdown() {
            if (this.expanded) this.blurDropdown()
            else this.focusDropdown();
        },
        toggleIfTrigger() {
            this.computedInc++;

            let dropdown       = this.$refs.dropdown,
                selectAll      = dropdown.querySelector('input:not([name])'),
                options        = Array.from(dropdown.querySelectorAll('input[name]')),
                checkedOptions = Array.from(dropdown.querySelectorAll('input[name]:checked')),
                noneChecked    = !checkedOptions.length,
                notAllChecked  = checkedOptions.length && checkedOptions.length !== options.length;

            if (noneChecked) { // None
                selectAll.indeterminate = false;
                selectAll.checked = false;
            } else if (notAllChecked) { // Some
                selectAll.indeterminate = true;
                selectAll.checked = false;
            } else { // All
                selectAll.indeterminate = false;
                selectAll.checked = true;
            }
        },
        focusDropdown() {
            this.expanded = true;
        },
        blurDropdown() {
            if (!event.target.closest('.fb-dropdown-container')) this.expanded = false;
        },
        selectAll() {
            this.computedInc++;
            let dropdown       = this.$refs.dropdown;
            let options        = Array.from(dropdown.querySelectorAll('input[name]'));
            let checkedOptions = Array.from(dropdown.querySelectorAll('input[name]:checked'));
            if (checkedOptions.length) {
                checkedOptions.forEach(option => option.checked = false);
                event.target.checked = false;
            } else {
                options.forEach(option => option.checked = true);
                event.target.checked = true;
            }
        },
    },
    watch: {
        expanded(n) {
            let vm     = this,
                el     = this.$refs.dropdown,
                height = el.scrollHeight,
                capped = height > 205 ? 205 : height;

            el.style.setProperty('--height', capped + 'px');

            if (n) setTimeout(function() { document.body.addEventListener('click', vm.blurDropdown) }, 0)
            else setTimeout(function() { document.body.removeEventListener('click', vm.blurDropdown) }, 0)
        },
        computedInc(n) {
            let checked = Array.from(this.$refs.dropdown.querySelectorAll('input[name]:checked'));
            this.$emit('update-selected', checked.map(i => i.value));
        }
    },
    mounted() {
        this.mounted = true
        // this.fetchStates();
    }
}
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '../../../sass/_vue-import.scss';
$gbs-color: rgba(0,0,0,0.3);
$gbs-pos: inset 0 0;
$global-box-shadow: $gbs-pos 3px $gbs-color;

.fb-dropdown {
    position: relative;
}

// Group (containing all parts of the input)
.fb-group {
    display: block;
    margin: 0;
}

// Label (real or styled)
.fb-label {
    font-size: .875rem;
    line-height: 1.1;
    display: block;
    padding: .25rem 0;
    margin: 0;
}

// Required *
.fb-required {
    color: var(--danger);
}

// Basic Control
.fb-control {
    display: block;
    width: 100%;
    min-height: 32px;
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: 3px;
    border: 0;
    outline: 0;
    background-color: white;
    box-shadow: $global-box-shadow;
    transition: 200ms ease-out;

    &:focus {
        box-shadow: $gbs-pos 0 2px var(--gray-dark);
    }

    // Valid styles
    .fb-valid & {
        box-shadow: $gbs-pos 0 1px var(--success);
        &:focus { box-shadow: $gbs-pos 0 2px var(--success); }
    }

    // Invalid Styles
    .fb-invalid & {
        box-shadow: $gbs-pos 0 1px var(--danger);
        &:focus { box-shadow: $gbs-pos 0 2px var(--danger); }
    }
}

// Option Controls
.fb-legend {
    font-size: .875rem;
    line-height: 1.1;
    display: block;
    padding: .25rem 0;
    margin: 0 0 .5rem 0 !important;
}

// All options
.fb-options {
    align-items: flex-start;
    position: relative;
    padding: 0;
    margin: 0;
    width: 100%;

    &.expanded {
        .fb-dropdown-container {
            height: var(--height);
            overflow: auto;
        }

        .fb-dropdown-toggle {
            &:before { transform: translateY(-50%) rotate(-180deg); }
        }
    }

    .fb-option {
        padding: .25rem .5rem;
        position: relative;
        margin: 0;
        display: flex;
        align-items: flex-start;
        cursor: pointer;
        line-height: 1.1;

        input {
            margin-top: calc((1.1em - 13px) / 2);
            margin-bottom: calc((1.1em - 13px) / 2);
            margin-right: .5rem;
        }
    }
}

// Fieldset Dropdown Styles
.fb-dropdown-toggle {
    position: relative;
    padding-right: 2.25rem;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: auto;
    gap: .25rem;

    &:before {
        --ca-thickness: 20%;
        content: "";
        position: absolute;
        top: 50%;
        right: .75rem;
        transform: translateY(-50%) rotate(0);
        background-color: hsl(0deg,0%,85%);
        width: .75rem;
        height: 6px;
        transition: 200ms ease-out;
        clip-path: polygon(0% 0%,50% 100%,100% 0%,calc(100% - var(--ca-thickness)) 0%,50% calc(100% - var(--ca-thickness) * 2),calc(var(--ca-thickness)) 0%);
    }

    ::v-deep span {
        padding: .125rem .5rem .0625rem;
        border-radius: .55em;
        background-color: hsl(0deg, 0%, 90%);
        font-size: .75rem;
        font-weight: bold;
    }

    // Invalid Styles
    .fb-invalid:focus-within &,
    .fb-invalid & {
        box-shadow: $gbs-pos 0 1px var(--danger);
    }
}

.fb-dropdown-container {
    position: absolute;
    z-index: 1;
    background-color: white;
    overflow: hidden;
    height: 0;
    width: 100%;
    transition: 200ms ease-out;
    box-shadow: $global-box-shadow;

    &::-webkit-scrollbar-track { background: #e9ecef; }
    &::-webkit-scrollbar-thumb { background: rgba(0,0,0,0.2); }
    &::-webkit-scrollbar-thumb:hover { background: rgba(0,0,0,0.4); }
    &::-webkit-scrollbar {
        width: .5rem;
        height: .5rem;
    }
}

// Assistive Text
.fb-assistive {
    font-size: .75rem;
    margin-top: 4px !important;
    margin-bottom: 0 !important;
    line-height: 1.1;
    color: hsl(0deg,0%,40%);
    display: none;

    &.format { display: block; }

    span { font-weight: bold; }

    // Invalid Styles
    .fb-invalid & {
        color: var(--danger);
        display: block;
    }
}
</style>