<template>
    <div class="blog-details">
        <div class="blog-top-bar">
            <p class="blog-title">{{ activeRecord.title }}</p>
            <a :href="getBackButtonUrl" class="go-back" title="Internal link back to the listing page in the same window"><span class="fas fa-chevron-left"></span>Back to List</a>
        </div>

        <div class="blog-details">
            <div class="blog-author-image"></div> <!-- I guess an author image would be added here??? -->
            <div class="blog-info">
                <p class="blog-date">
                    <span class="fas fa-calendar"></span>
                    {{ activeRecord.scheduled_at }}
                </p>
                <p class="blog-author"><span class="fas fa-user"></span>{{ activeRecord.author_name }}</p>
                <div class="categories" v-if="activeRecord.categories.length">
                    <a
                        class="category"
                        :key="'category_' + catId"
                        :href="categories[catId].permalink"
                        v-for="catId in activeRecord.categories"
                        :title="`Internal link to the ${categories[catId].name} category in the same window.`"
                    >
                        <span class="fas fa-box"></span>
                        {{ categories[catId].name }}
                    </a>
                </div>
            </div>
        </div>

        <div class="blog-image">
            <img :src="activeRecord.featured_image" :alt="activeRecord.featured_image_alt" />
        </div>

        <div class="content" v-html="activeRecord.content"></div>

        <div class="blog-tags">
            <a
                class="tag"
                :href="tagPermalink"
                :key="'bd-tag_' + tagName"
                v-for="(tagPermalink, tagName) in activeRecord.tags"
                :title="`Internal link to the ${tagName} tag in the same window.`"
            >
                <span class="fas fa-tag"></span>{{ tagName }}
            </a>
        </div>
        <a :href="getBackButtonUrl" class="go-back" title="Internal link back to the listing page in the same window"><span class="fas fa-chevron-left"></span>Back to List</a>
        <!-- Go to www.addthis.com/dashboard to customize your tools -->
        <div class="addthis_inline_share_toolbox_llow"></div>

        <SiteBlogComments
            :blog="activeRecord"
            v-if="activeRecord.allow_comments"
        ></SiteBlogComments>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState('blog', [
            'categories',
            'activeRecord'
        ]),
        getBackButtonUrl(){
            let urlToGoTo,
            blogUrl     = this.activeRecord.blogUrl, //get the dynamic blog url
            urlCameFrom = document.referrer ? new URL(document.referrer) : null, //make URL interface out of where the user came from
            urlPath     = urlCameFrom?.pathname; //get the path name from where the user came from

            //if there is a path name to go back to and the path includes the blog url
            if(urlPath && document.referrer.includes(blogUrl)){
                //if the url includes post go back to the blog listing otherwise go to back to where the list that the user came from
                urlToGoTo = urlPath.includes('post') ? blogUrl : urlPath;
            } else {
                //else just go back to the overall blog listing
                urlToGoTo = blogUrl;
            }

            return urlToGoTo;
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../../../../../sass/_vue-import.scss';

    %badge {
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: .25rem .5rem;
        padding: .25rem .5rem;
        background-color: hsl(0deg,0%,95%);
        color: hsl(0deg,0%,15%);
        line-height: 1;
        cursor: pointer;
        border-radius: 3px;

        svg {
            margin-right: .25rem;
            font-size: 10px;
        }
    }

   .blog-details { margin: 0 0 2rem; }

    .blog-top-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 1rem;

        .blog-title {
            @include fluid-size(18px, 32px, font-size);
            font-weight: bold;
            margin: 0;
        }
    }

    .go-back {
        background: none;
        outline: none;
        border: none;
        padding: 0;
        font-weight: bold;

        svg { margin-right: .5rem; }
    }

    .blog-info {
        display: flex;
        flex-wrap: wrap;
        margin: -.25rem -.5rem;
        font-size: 14px;

        .blog-date, .blog-author {
            margin: .25rem .5rem;
            display: flex;
            align-items: center;
            line-height: 1;

            &::v-deep svg { margin-right: .5rem; }
        }

        .categories {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .category {
                @extend %badge;
                background-color: hsl(0deg,0%,85%);
                text-decoration: none;
            }
        }
    }


    .blog-image img { max-width: 100%; }
    .content { margin-top: 1rem; }

    .blog-tags {
        display: flex;
        flex-wrap: wrap;
        font-size: 12px;
        margin: -.25rem -.5rem;
        padding: 1rem 0;

        .tag {
            @extend %badge;
            text-decoration: none;
        }
    }

    .addthis_inline_share_toolbox_llow {
        margin-top: 1.5rem;
    }
</style>