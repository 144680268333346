<template>
    <div class="preview-body" :style="themeProps">
        <div class="header">
            <div class="top">
                <div class="fake-links"></div>
                <div class="fake-links"></div>
            </div>
            <div class="bottom">
                <div class="left">
                    <div class="logo">
                        <img :src="logoPath" alt="preview logo">
                    </div>
                    <div class="fake-links"></div>
                </div>
                <div class="fake-links"></div>
            </div>
        </div>
        <div class="page-title">
            <div class="title"></div>
            <div class="breadcrumbs"></div>
        </div>
        <div class="main">
            <p class="heading">Heading 1</p>
            <p class="heading">Heading 2</p>
            <p class="heading">Heading 3</p>
            <p class="heading">Heading 4</p>
            <p class="heading">Heading 5</p>
            <p class="heading">Heading 6</p>
            <p class="body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            <button class="btn btn-sm">Button</button>
            <button class="btn btn-sm">Button</button>
        </div>
        <div class="footer">
            <div class="top">
                <div class="fake-links"></div>
            </div>
            <div class="bottom">
                <div class="left">
                    <div class="fake-links"></div>
                </div>
                <div class="right">
                    <div class="fake-links"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { themingMixin } from '../../../../../Core/Site/Mixins/themingMixin';
import { headerMixin } from '../../../../../Core/Site/Mixins/headerMixin';

export default {
    mixins: [
        themingMixin,
        headerMixin
    ],
    name: "SiteDesignPreview",
    props: [
        "designConfig"
    ],
    computed: {
        themeProps() {
            return this.themeProperties.reduce((a,c) => {
                a[c.prop] = c.value;
                return a;
            }, {});
        }
    }
}
</script>

<style lang="scss" scoped>
    // Josefin Slab, Lato, Lora, Montserrat, Noto Sans JP, Open Sans, Playfair Display, Poppins, Quicksand, Roboto
    @import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,100;0,400;0,700;1,100;1,400;1,700&family=Lato:ital,wght@0,400;0,700;1,400;1,700&family=Lora:ital,wght@0,400;0,700;1,400;1,700&family=Montserrat:ital,wght@0,400;0,700;1,400;1,700&family=Noto+Sans+JP:wght@400;700&family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Playfair+Display:ital,wght@0,400;0,700;1,400;1,700&family=Poppins:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&family=Quicksand:wght@300;400;700&family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

    $theme-color: var(--theme-color, 255, 255, 255);
    $theme-complement: var(--theme-complement, 0, 0, 0);
    $theme-complement-compliant: var(--theme-complement-compliant, 255, 255, 255);
    $optimium-on-white: var(--optimum-on-white, 0, 0, 0);
    $border: 1px solid;

    .preview-body {
        width: 100%;
        border: $border;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .header {
        background-color: rgb($theme-color);
        padding: 0 10%;

        .top {
            padding: 4px 0;
            display: flex;
            justify-content: space-between;

            + .bottom { border-top: $border }
        }

        .bottom {
            padding: 4px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left {
                display: flex;
                align-items: center;

                .logo {
                    margin-right: 8px;

                    img {
                        height: 24px;
                        width: 24px;
                        object-fit: contain;
                        vertical-align: bottom;
                    }
                }
            }
        }
    }

    .page-title {
        padding: 8px 10%;
        background-color: rgb($theme-complement);

        .title {
            height: 8px;
            width: 76px;
            background-color: rgb($theme-color);
            margin-bottom: 4px;
        }

        .breadcrumbs {
            position: relative;
            height: 4px;
            width: 38px;
            background-image: linear-gradient(
                to right,
                rgb($theme-color) 0,
                rgb($theme-color) 15px,
                transparent 15px,
                transparent 23px,
                rgb($theme-color) 23px,
                rgb($theme-color) 38px
            );

            &:before {
                position: absolute;
                font-size: 6px;
                color: rgb($theme-color);
                content: "/";
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .main {
        flex-grow: 1;
        padding: 10px 10%;

        p {
            margin: 0 0 .25em;
            line-height: 1;
            color: black;
        }

        .heading {
            font-family: var(--display-font-family);
            font-weight: bold;
            color: rgb($optimium-on-white);

            &:nth-child(1) { font-size: 36px; }
            &:nth-child(2) { font-size: 32px; }
            &:nth-child(3) { font-size: 28px; }
            &:nth-child(4) { font-size: 24px; }
            &:nth-child(5) { font-size: 20px; }
            &:nth-child(6) { font-size: 16px; }
        }

        .body {
            margin-top: 1em;
            font-size: 12px;
            line-height: 1.25;
            font-family: var(--body-font-family);
        }

        button:nth-of-type(1) {
            background-color: rgb(var(--theme-color));
            color: rgb(var(--theme-compliant));
            font-family: var(--body-font-family);
        }

        button:nth-of-type(2) {
            background-color: rgb(var(--theme-complement));
            color: rgb(var(--theme-complement-compliant));
            font-family: var(--body-font-family);
        }
    }

    .footer {
        padding: 0 10%;
        background-color: rgb($theme-complement);

        .fake-links {
            background-image: linear-gradient(
                to right,
                rgb($theme-color) 0,
                rgb($theme-color) 20px,
                transparent 20px,
                transparent 28px,
                rgb($theme-color) 28px,
                rgb($theme-color) 48px,
                transparent 48px,
                transparent 56px,
                rgb($theme-color) 56px,
                rgb($theme-color) 76px
            );
        }

        .top {
            padding: 4px 0;
            display: flex;
            justify-content: center;

            + .bottom { border-top: $border rgb($theme-color) }
        }

        .bottom {
            padding: 4px 0;
            display: flex;
            justify-content: space-between;
        }
    }

    .fake-links {
        height: 5px;
        width: 76px;
        background-image: linear-gradient(
            to right,
            rgb($theme-complement) 0,
            rgb($theme-complement) 20px,
            transparent 20px,
            transparent 28px,
            rgb($theme-complement) 28px,
            rgb($theme-complement) 48px,
            transparent 48px,
            transparent 56px,
            rgb($theme-complement) 56px,
            rgb($theme-complement) 76px
        )
    }
</style>