<template>
    <div>
        <div class="filter-bar" v-if="getCurrentFilterName">
            <p class="filter-title">{{ getCurrentFilterName }}</p>
            <button class="reset-filter" @click="resetFilter()"><span class="fas fa-chevron-left mr-1"></span>Reset Filter</button>
        </div>

        <frontend-blog-list-item
            v-if="firstFeaturedBlogId && !getCurrentFilterName && !searchFilter"
            :id="firstFeaturedBlogId"
            :blog="blogs[firstFeaturedBlogId]"
            :featured="true"
        ></frontend-blog-list-item>

        <!-- All blogs ordered by date I think -->
        <frontend-blog-list-item
            v-for="id in paginatedValues"
            :key="'post_' + id"
            :id="id"
            :blog="blogs[id]"
        ></frontend-blog-list-item>

        <div class="py-5" v-if="!paginatedValues.length && (searchFilter || !firstFeaturedBlogId)">
            <p class="text-14 text-center">Sorry, we can't find any blogs with those filters.<br/>Try refining your search or <a href="#" @click.prevent="resetFilter()" title="Reset your Filters">clearing your filters</a>.</p>
        </div>

        <pagination
            :active-page="activePage"
            :item-count="totalItems"
            :items-per-page="itemsPerPage"
            @on-update="setPage($event)"
        ></pagination>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
    name:'FrontendBlogList',
    computed: {
        ...mapState('blog', [
            'activePage',
            'activeFilterType',
            'blogs',
            'itemsPerPage',
            'searchFilter'
        ]),
        ...mapGetters('blog', [
            'totalItems',
            'paginatedValues',
            'firstFeaturedBlogId',
            'getCurrentFilterName',
        ])
    },
    methods: {
        ...mapActions('blog', [
            'resetFilter',
            'setPage',
        ])
    }
};
</script>

<style lang="scss" scoped>
    @import '../../../../../../sass/_vue-import.scss';

    .filter-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 1rem;
    }

    .filter-title {
        @include fluid-size(18px, 32px, font-size);
        font-weight: bold;
        margin: 0;
    }

    .reset-filter {
        background: none;
        outline: none;
        border: none;
        padding: 0;
        font-weight: bold;

        svg { margin-right: .5rem; }
    }
</style>