<template>
    <div class="page-title">
        <div class="pt-container">
            <h1 class="pt-title">{{ pageTitle }}</h1>
            <div class="pt-breadcrumbs">
                <template v-for="(page, index) in breadcrumbs">
                    <p
                        :key="`${index}-sep`"
                        class="ptbc-separator"
                        v-if="index > 0"
                    >{{ breadcrumbSeparator }}</p>
                    <vue-link
                        :key="`${index}-link`"
                        :link-text="page.title"
                        :link-path="(!page.header && page.current != 1) ? page.url : '#'"
                    ></vue-link>
                </template>
            </div>
        </div>
        <div class="pt-bg" :style="backgroundImage"></div>
    </div>
</template>

<script>
import { themingMixin } from '../../Mixins/themingMixin';
import { pageTitleMixin } from '../../Mixins/pageTitleMixin';

export default {
    mixins: [
        themingMixin,
        pageTitleMixin
    ],
    computed: {
        backgroundImage() {
            return `background-image: url(${this.ptBackground || '"/images/custom/site/pt-bg-default.jpg"'})`;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../../../sass/_vue-import.scss';
$theme-color: var(--theme-complement, 255, 255, 255);
$theme-complement: var(--theme-color, 0, 0, 0);
$theme-complement-compliant: var(--theme-complement, 255, 255, 255);

// Page Title Variables -------------------------
    $pt-bg-position: center;
    $pt-bg-size: cover;
    $pt-bg-repeat: no-repeat;
    $pt-padding-y: 2rem;
    $pt-overlay-color: rgba($theme-color, .8);
    $pt-h1-color: rgb($theme-complement);
    $pt-bg-color: rgb($theme-complement-compliant); // This is here so the text doesn't flag as not meeting contrast

    // Overlay color and text color based on
    // semi-transparent contrast ratio found here
    // https://contrast-ratio.com/#white-on-rgba%280%2C0%2C0%2C0.54%29
    // If you use a different color adjust the transparency
    // and make sure the text meets contrast checks with + and -

    .page-title {
        width: 100%;
        background-color: $pt-bg-color;
        opacity: 1;
        transition: 200ms ease-out;
        position: relative;

        @include not-loaded { opacity: 0; }

        .pt-container {
            position: relative;
            width: 100%;
            max-width: $container-width;
            padding-top: $pt-padding-y;
            padding-bottom: $pt-padding-y;
            padding-left: 15px;
            padding-right: 15px;
            margin-left: auto;
            margin-right: auto;
            z-index: 1;
        }

        .pt-bg {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            background-position: $pt-bg-position;
            background-size: $pt-bg-size;
            background-repeat: $pt-bg-repeat;

            &:before {
                content: '';
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                background-color: $pt-overlay-color;
            }
        }

        .pt-title {
            margin: 0;
            line-height: 1.25;
            color: $pt-h1-color;
        }

        .pt-breadcrumbs {
            display: flex;
            color: $pt-h1-color;

            * { color: $pt-h1-color; }

            .ptbc-separator {
                margin: 0 .5rem;
            }
        }
    }
</style>