<template>
    <div class="site-config-section">
        <div class="mb-1 d-flex flex-row justify-content-between">
            <div class="site-config-section-header d-flex flex-column">
                <p>From this screen you can modify the site configuration options for your site. Click on the tabs above to edit robots.txt or sitemap.xml.</p>
            </div>
            <div class="site-config-section-button d-flex flex-column justify-content-end mb-1">
                <button class="btn btn-primary" @click.prevent="updateSiteConfig">{{ isFormProcessing ? 'Processing...' : 'Update Site Config' }}</button>
            </div>
        </div>

        <site-config-list :configs="configs"></site-config-list>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
    name: 'SiteConfigLayout',
    computed: {
        ...mapState('siteConfig', {
            configs: state => state.configs,
            isFormProcessing: state => state.isFormProcessing
        })
    },
    methods: {
        ...mapActions('siteConfig', {
            fetchSiteConfigData: 'fetchSiteConfigData',
            updateSiteConfig: 'updateSiteConfig',
        }),
    },
    created() {
        this.fetchSiteConfigData();
    }
}
</script>