export const pageTitleMixin = {
    props: {
        breadcrumbs: {
            type: Array,
        },
        breadcrumbSeparator: {
            type: String,
            default: ">"
        }
    },
    computed: {
        ptBackground() {
            return this.pageTitleImage || '/images/core/site/bg-core-page-title.jpg';
        },
        pageTitle() {
            return this.currentPageTitle;
        },
        pageTitleTemplate() {
            return this.designConfig?.template?.page_title_template || 1;
        }
    },
    mounted() {
        document.body.dataset.pageTitleTemplate = this.pageTitleTemplate;
    }
}

export default { pageTitleMixin }