<template >
	<div class="form-view">
        <!-- Header Area -->
		<div class="p-2">
			<h5 class="modal-title"><span class="fas fa-edit mr-1 text-tertiary"></span>{{ getHeader }}</h5>
			<div class="vp-status-bar-list pb-2" v-if="!isActionAdd">
				<p>Created Date: <span>{{ formatDate(activeRecord.created_at) }}</span></p>
				<p>Last Modified: <span>{{ formatDate(activeRecord.updated_at) }}</span></p>
				<p v-if="activeRecord.is_active">Status: <span>{{ activeRecord.is_active ? 'Active' : 'Inactive' }}</span></p>
			</div>
		</div>

        <!-- Body Area -->
		<form
			id="add-modify-blog-form"
			class="category-form needs-validation p-2"
			name="add-modify-blog-form"
			novalidate
			@submit.prevent="submitForm"
		>
			<div class="form-errors"></div>
			<input type="hidden" name="_token" :value="activeRecord._token" />
			<input type="hidden" name="id" value="activeRecord.id" v-if="!isActionAdd" />

            <div class="row">
                <!-- Left Side -->
                <div class="col-md-8">
                    <h5>Basic Information</h5>

                    <!-- Title -->
                    <div class="form-group has-label">
                        <div class="d-flex flex-row justify-content-start align-items-center flex-wrap">
                            <label for="title">Title <span class="required">*</span></label>
                            <p class="my-0 ml-3 text-info text-14">{{ formattedUrl }}</p>
                        </div>
                        <input
                            type="text"
                            class="form-control"
                            name="title"
                            id="title"
                            v-model="activeRecord.title"
                            @keyup="createPermalink()"
                            title="Enter a Post Title"
                            required
                        />
                        <p class="invalid-feedback">Please enter a title.</p>
                    </div>

                    <!-- Author -->
                    <div class="form-group has-label">
                        <label for="author_name">Author<span class="required">*</span></label>
                        <input
                            type="text"
                            class="form-control"
                            id="author_name"
                            v-model="activeRecord.author_name"
                            title="Please enter the Author's name."
                            required
                        />
                        <p class="invalid-feedback">Please enter the Author's name.</p>
                    </div>

                    <!-- Content -->
                    <h5>Content <span class="required">*</span></h5>
                    <textarea
                        name="content"
                        id="content"
                        v-model="activeRecord.content"
                        title="Enter Content"
                        required
                    ></textarea>

                    <h5 class="mt-2">SEO Settings</h5>

                    <!-- Meta Title -->
                    <div class="form-group has-label">
                        <label for="meta_title">Meta Title <span class="required">*</span></label>
                        <input
                            type="text"
                            name="meta_title"
                            class="form-control"
                            id="meta_title"
                            v-model="activeRecord.meta_title"
                            title="Please Enter meta title."
                            required
                        />
                        <p class="invalid-feedback">Please enter a meta title</p>
                    </div>

                    <!-- Meta Keywords -->
                    <div class="form-group has-label">
                        <label for="meta_title">Meta Keywords <span class="required">*</span></label>
                        <input
                            type="text"
                            name="meta_keywords"
                            class="form-control"
                            id="meta_keywords"
                            v-model="activeRecord.meta_keywords"
                            title="Please enter meta keywords."
                            required
                        />
                        <p class="invalid-feedback">Please enter meta keywords.</p>
                    </div>

                    <!-- Meta Description -->
                    <div class="form-group has-label">
                        <label for="content">Meta Description <span class="required">*</span></label>
                        <textarea
                            id="meta_description"
                            name="meta_description"
                            class="form-control"
                            v-model="activeRecord.meta_description"
                            title="Please enter a meta description."
                            required
                        ></textarea>
                        <p class="invalid-feedback">Please enter a meta description.</p>
                    </div>
                </div>

                <!-- Right Side -->
                <div class="col-md-4">

                    <!-- Publish Information -->
                    <h5>Publish Information</h5>
                    <div class="publish-info px-2 py-1 border rounded mb-2">
                        <div class="mx-n2 mt-n1 mb-1 py-1 px-2 border-bottom">
                            <div class="d-flex justify-content-between">
                                <p class="m-0">Publish: {{ activeRecord.scheduled_at ? formatDateTime(activeRecord.scheduled_at) : 'Immediately' }}</p>

                                <button
                                    type="button"
                                    class="btn btn-sm primary"
                                    @click="toggleDatetime = !toggleDatetime"
                                >Edit</button>
                            </div>

                            <div class="row flex-wrap" v-if="toggleDatetime">
                                <div class="col-md-6">
                                    <div class="form-group mb-0 mt-1">
                                        <input
                                            type="date"
                                            class="form-control"
                                            v-model="date"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group mb-0 mt-1">
                                        <input
                                            type="time"
                                            class="form-control"
                                            v-model="time"
                                        />
                                    </div>
                                </div>
                            </div>

                            <input
                                type="hidden"
                                name="scheduled_at"
                                :value="activeRecord.scheduled_at"
                            >
                        </div>

                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                name="featured"
                                id="featured"
                                v-model="activeRecord.featured"
                            />
                            <label
                                class="form-check-label"
                                for="featured"
                            >Is Featured Post?</label>
                        </div>

                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                name="allow_comments"
                                id="allow_comments"
                                v-model="activeRecord.allow_comments"
                            />
                            <label
                                class="form-check-label"
                                for="allow_comments"
                            >Allow Comments?</label>
                        </div>

                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                name="is_active"
                                id="is_active"
                                v-model="activeRecord.is_active"
                            />
                            <label
                                class="form-check-label"
                                for="is_active"
                            >Is Active?</label>
                        </div>
                    </div>

                    <!-- Categories -->
                    <div class="form-group flex-column">
                        <select
                            id="categories"
                            name="categories"
                            v-model="activeRecord.categories"
                            class="form-control"
                            title="Please select categories for this post."
                            multiple
                        >
                            <option
                                v-for="(category, index) in categories"
                                :key="index"
                                :value="category.id"
                            >{{ category.name }}</option>
                        </select>
                        <label for="categories">Categories</label>

                        <p class="invalid-feedback">Please check post categories.</p>

                        <div class="mb-2 d-flex justify-content-end">
                            <add-new-category-button />
                        </div>
                    </div>

                    <!-- Tags -->
                    <div class="form-group flex-column">
                        <select
                            id="tags"
                            name="tags"
                            v-model="activeRecord.tags"
                            class="form-control"
                            title="Please select tags for this post."
                            multiple
                        >
                            <option
                                v-for="(tag, index) in tags"
                                :key="index"
                                :value="tag.id"
                            >{{ tag.name }}</option>
                        </select>
                        <label for="tags">Tags</label>

                        <p class="invalid-feedback">Please select tags for this post.</p>

                        <div class="mb-2 d-flex justify-content-end">
                            <add-new-tag-button />
                        </div>
                    </div>

                    <!-- Featured Image -->
                    <div class="form-group has-label flex-column">
                        <img
                            v-if="activeRecord.featured_image"
                            class="mb-2"
                            id="featuredImage"
                            :src="activeRecord.featured_image"
                            :alt="activeRecord.featured_image_alt"
                        />

                        <input
                            class="form-control"
                            type="hidden"
                            id="featured_image_path"
                            name="featured_image"
                            autocomplete="off"
                            v-model="activeRecord.featured_image"
                            title="Please enter featured image"
                            data-invalid-feedback="Add a featured image"
                            hidden
                        />

                        <input
                            class="form-control"
                            type="text"
                            id="featured_image_alt"
                            name="featured_image"
                            v-model="activeRecord.featured_image_alt"
                            title="Please enter featured image alt text"
                            required
                        />

                        <label for="featuredImage">Featured Image Alt Text <span class="required">*</span></label>

                        <div class="mb-2 d-flex justify-content-end">
                            <button
                                @click.prevent="openFileManager('feat')"
                                class="btn btn-tertiary"
                                id="featuredImageButton"
                                data-input="image_path"
                                title="Opens your file manager where you can choose or upload a new file from your file library"
                            >Set Header Image</button>
                        </div>

                        <p class="invalid-feedback">Open the file manager modal where you can choose to upload a new file or choose from your file library.</p>
                    </div>

                    <!-- Thumbnail Image -->
                    <div class="form-group has-label flex-column">
                        <img
                            v-if="activeRecord.thumbnail_image"
                            class="mb-2"
                            id="thumbImage"
                            :src="activeRecord.thumbnail_image"
                            :alt="activeRecord.thumbnail_image_alt"
                        />

                        <input
                            class="form-control"
                            type="hidden"
                            id="thumb_image_path"
                            name="thumbnail_image"
                            autocomplete="off"
                            v-model="activeRecord.thumbnail_image"
                            title="Please enter thumbnail image"
                            data-invalid-feedback="Add a thumbnail image"
                            hidden
                        />

                        <input
                            class="form-control"
                            type="text"
                            id="thumbImage"
                            name="thumbnail_image_alt"
                            v-model="activeRecord.thumbnail_image_alt"
                            title="Please enter thumbnail image alt text"
                            required
                        />

                        <label for="featuredImage">Thumbnail Image <span class="required">*</span></label>

                        <div class="mb-2 d-flex justify-content-end">
                            <button
                                @click.prevent="openFileManager('thumb')"
                                class="btn btn-tertiary"
                                id="thumbImageButton"
                                data-input="thumb_image_path"
                                title="Opens your file manager where you can choose or upload a new file from your file library"
                            >Set Thumbnail Image</button>
                        </div>
                    </div>
                </div>
            </div>

			<div class="d-flex justify-content-end m-n1">
               <!-- todo fix preview function <button type="button" class="btn btn-secondary m-1" :disabled="isDisabled" @click="openPreview()">Preview</button>-->
				<button type="submit" class="btn btn-secondary m-1" @click="setContent()">Save</button>
			</div>
		</form>

        <add-modify-tag-modal />
        <add-modify-category-modal />
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { openFileManager } from "../../../../../../../../resources/js/core/vpanel/vpanel-functions";
import dayjs from 'dayjs';

export default {
    name: 'AddModifyBlogForm',
    created(){
        this.fetchCategories();
        this.fetchTags();
    },
    mounted() {
        if (this.activeRecord.isActionAdd){
            this.resetBlog;
        }
    },
    data() {
        return {
            selected:[],
            toggleDatetime:false,
            date: '',
            time: ''
        }
    },
    watch: {
        activeRecord: {
            handler: function(val, oldVal) {
                this.setDidSaveDraft(false);
            },
            deep: true
        },
        date(n,o) {
            this.setDateTime(n, this.time);
        },
        time(n,o) {
            this.setDateTime(this.date, n);
        }
    },
    computed:{
        ...mapState('blog/addModifyBlog', {
            activeRecord: state => state.activeRecord,
            isActionAdd: state => state.formActionIsAdd,
            isFormProcessing: state => state.isFormProcessing,
            didSaveDraft:  state => state.didSaveDraft,
        }),
        ...mapState('category/list', {
            categories: state =>state.tableData,
        }),
        ...mapState('tag/list', {
            tags: state =>state.tableData,
        }),
        getHeader() {
            let action = this.isActionAdd ? 'Add a New ' : 'Modify ';
            return action + 'Blog Post';
        },

        isDisabled() {
            return !this.didSaveDraft;
        },
        formattedUrl() {
            return `${location.origin}/blog/${(this.activeRecord.permalink || '')}`;
        }
    },
    methods:{
        ...mapActions("category", {
            fetchCategories: 'list/fetchCategories',
        }),
        ...mapActions("tag", {
            fetchTags: 'list/fetchTags',
        }),
        ...mapActions('blog/addModifyBlog', [
            'submitForm',
            'openPreview',
            'setDidSaveDraft',
            'resetBlog'
        ]),
        formatDate(date) {
            return dayjs(date).format('MMMM D, YYYY');
        },
        formatDateTime(date) {
            return dayjs(date).format('MMMM D, YYYY @ h:mm A');
        },
        openFileManager(image) {
            if (image == 'thumb') {
                openFileManager(event.target, this.setThumbImage);
            } else {
                openFileManager(event.target, this.setImage);
            }
        },
        setImage(path){
            this.activeRecord.featured_image = path;
        },
        setThumbImage(path){
            this.activeRecord.thumbnail_image=path;
        },
        createPermalink(){
            // Doing this method may cause posts with (technically) different titles to have the same permalink
            // 'Blog Post 1' & 'Blog Post 1!' would both generate out to blog-post-1 so checks may need to be re-added for the permalink
            let post = this.activeRecord.title;
            let scrub = post.replace(/[^A-Za-z0-9\s-_.~]|[!*\'();:@&=+$,/?%#\[\]]/g, '')
                            .replace(/[-_.~]/g, ' ')
                            .replace(/ {1,}/g, '-')
                            .replace(/(^-|-$)/g, '')
                            .toLowerCase();

            this.activeRecord.permalink = scrub;
        },
        setContent(){
            if (!this.activeRecord.scheduled_at) this.activeRecord.scheduled_at = dayjs().format("YYYY-MM-DD HH:mm:ss");
            this.activeRecord.content = CKEDITOR.instances.content.getData();
        },
        setDateTime(d, t) {
            this.activeRecord.scheduled_at = dayjs(`${d} ${t}`).format("YYYY-MM-DD HH:mm:ss");
        }
    },
    mounted() {

        // set date and time inputs if scheduled_at is set
        if (this.activeRecord.scheduled_at) {
            this.date = dayjs(this.activeRecord.scheduled_at).format("YYYY-MM-DD");
            this.time = dayjs(this.activeRecord.scheduled_at).format("HH:mm");
        }

        // Set up CKEDITOR instance
        let contentTextarea = document.querySelector("#content");

        CKEDITOR.replace(contentTextarea, {
            filebrowserImageBrowseUrl:  '/vpanel/file-manager/app',
            filebrowserImageUploadUrl: '/vpanel/file-manager/app' + '/upload?responseType=json&_token=' + this.activeRecord._token,
            filebrowserBrowseUrl: '/vpanel/file-manager/app',
            filebrowserUploadUrl: '/vpanel/file-manager/app' + '/upload?responseType=json&_token=' + this.activeRecord._token,
            extraPlugins: 'dialogui,dialog,notification,clipboard,textmatch,fakeobjects,link,autolink,youtube',
            removePlugins: "maximize",
            height: 600,
            toolbar: [
                { items: ["Source", "Undo", "Redo", "PasteText", "PasteFromWord", "-"] },
                { items: ["Styles","Format" ,"FontSize", "TextColor", "-"] },
                { items: ["Bold", "Italic", "Strike", "RemoveFormat", "-"] },
                { items: ["NumberedList", "BulletedList", "-"] },
                { items: ["Indent", "Outdent", "JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock", "-"] },
                { items: ["Blockquote", "-"] },
                { items: ["Link", "Unlink", "Anchor", "-"] },
                { items: ["Image", "Table", "Iframe", "Youtube", "-", "Maximize", "-", "Scayt"] }
            ]
        });
        CKEDITOR.instances.content.setData(this.activeRecord.content);
    }
}
</script>

<style lang="scss" scoped>
    @import '../../../../../../../sass/_vue-import';

    .form-view {
        overflow: auto;
        @extend %minimal-scrollbar;
    }

    ul.no-bullets {
        list-style-type: none; /* Remove bullets */
        padding: 0; /* Remove padding */
        margin: 0; /* Remove margins */
    }

    .publish-info input[type="checkbox"] {
        opacity: 1;
        visibility: visible;
        position: static;
    }

    select[multiple] {
        padding: 0;

        option { padding: .25rem 1rem; }
    }
</style>