<template>
    <component
        v-if="searchTemplateName != 'hidden'"
        :is="templateComponent"
        v-bind="{...$props, ...$attrs}"
    ></component>
</template>

<script>
export default {
    props: [
        "searchTemplate",
    ],
    data() {
        return {
            searchTemplateName: this.searchTemplate,
        }
    },
    computed: {
        templateComponent() {
            return 'vue-site-search-template-' + (this.searchTemplateName || '1');
        },
    },
    methods: {
        closeMobileMenu(event) {
            this.$emit('close-mobile-menu');
        }
    },
    mounted() {
        document.body.dataset.searchTemplate = this.searchTemplateName || '1';
    }
}
</script>

<style lang="scss">

</style>