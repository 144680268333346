import tinycolor from 'tinycolor2';

export const themingMixin = {
    props: {
        designConfig: { type: Object },
        primaryColor: { type: String },
        secondaryColor: { type: String },
        currentPageTitle: { type: String },
        pageTitleImage: { type: String },
        pageUuid: { type: String },
        // This we'll probably want moved to the design options at some point
        // It's for variants of the headers (or potentially other templates)
        // i.e. "full" for a full width header
        variant: {
            type: [String, Boolean],
            default: false
        }
    },
    computed: {
        themePrimaryColor() {
            return this.primaryColor || this.designConfig?.theme?.primary_color || '#ffffff';
        },
        themeSecondaryColor() {
            return this.secondaryColor || this.designConfig?.theme?.secondary_color || '#000000';
        },
        contrastRatio() {
            let ratioFull = tinycolor.readability(this.themePrimaryColor,this.themeSecondaryColor);
            return Math.round(ratioFull * Math.pow(10, 2)) / Math.pow(10, 2);
        },
        themeComplement() {
            if (tinycolor.isReadable(this.themeSecondaryColor, this.themePrimaryColor)) return tinycolor(this.themeSecondaryColor).toRgb()
            return tinycolor.mostReadable(this.themePrimaryColor, ['#000','#fff']).toRgb();
        },
        themeColorCompliant() {
            return tinycolor.mostReadable(this.themePrimaryColor, ['#000', '#fff']).toRgb();
        },
        themeComplementCompliant() {
            return tinycolor.mostReadable(this.themeComplement, ['#000', '#fff']).toRgb();
        },
        optimumOnWhite() {
            if (tinycolor.isReadable('#fff', this.themePrimaryColor)) return tinycolor(this.themePrimaryColor).toRgb();
            if (tinycolor.isReadable('#fff', this.themeComplement)) return this.themeComplement;
            return tinycolor('black').toRgb();
        },
        logoPath() {
            return this.designConfig?.theme?.logo || '/images/custom/site/logo.png';
        },
        reversedLogoPath() {
            let logo = this.logoPath; // If reversed logo is not set or the background where it is to be used is too light, use original logo
            if (tinycolor(this.themeComplementCompliant).isLight()) {
                logo = null; // if background is dark but reversed logo is not set, set null
                if (this.designConfig?.theme?.reverse_logo) logo = this.designConfig.theme.reverse_logo; // if background is dark and reversed logo is set, use reversed logo
            }
            return logo;
        },
        themeProperties() {
            return [
                {
                    prop: '--theme-color',
                    value: this.getPlainRGB(tinycolor(this.themePrimaryColor).toRgb())
                },
                {
                    prop: '--theme-compliant',
                    value: this.getPlainRGB(this.themeColorCompliant)
                },
                {
                    prop: '--theme-complement',
                    value: this.getPlainRGB(this.themeComplement)
                },
                {
                    prop: '--theme-complement-compliant',
                    value: this.getPlainRGB(this.themeComplementCompliant)
                },
                {
                    prop: '--optimum-on-white',
                    value: this.getPlainRGB(this.optimumOnWhite)
                },
                {
                    prop: '--display-font-family',
                    value: this.designConfig?.theme?.display_font || "'Quicksand', sans-serif"
                },
                {
                    prop: '--body-font-family',
                    value: this.designConfig?.theme?.body_font || "'Montserrat', sans-serif"
                }
            ];
        }
    },
    methods: {
        getPlainRGB(obj) {
            return `${obj.r}, ${obj.g}, ${obj.b}`;
        }
    },
    mounted() {
        if (this.designConfig && this.themeProperties) {
            this.themeProperties.forEach(declaration => {
                document.documentElement.style.setProperty(declaration.prop, declaration.value);
            })
        }
    }
}

export default { themingMixin }