<template>
    <component
        v-if="footerTemplate != 'hidden'"
        :is="templateComponent"
        v-bind="{...$props,...$attrs}"
    ></component>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        designConfig: {
            type: Object,
        },
    },
    computed: {
        footerTemplate() {
            return this.designConfig?.template?.footer_template || 1;
        },
        templateComponent() {
            return `vue-footer-template-${this.footerTemplate}`;
        },
    }
}
</script>