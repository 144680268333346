<template>
	<div class="datatable-section p-2">
		<div class="mb-2">
			<add-new-blog-button />
		</div>
        <blog-list />
		<recycle-blog-modal />
	</div>
</template>

<script>
    import { mapActions } from "vuex";
    export default {
        name: "BlogIndex",
        created() {
            this.fetchBlog();
        },
        methods: {
            ...mapActions("blog", {
                fetchBlog: "list/fetchBlog",
            }),
        }
    };
</script>