<template>
    <div class="ams-add-new mb-3">
        <label class="text-uppercase text-tertiary font-weight-bold m-0" for="add-new-asset">Add a new:&nbsp;</label>
        <select class="text-uppercase mr-2" name="add-new-asset" id="add-new-asset" @change="openAddModal" title="Select an asset type to add">
            <option value="">Choose one</option>
            <option value="Variable Data" data-id="1">Variable Data</option>
            <option value="HTML Widget" data-id="2">HTML Widget</option>
            <option value="Code Snippet" data-id="3" v-bind="ifNotAdmin">Code Snippet</option>
            <option value="Module" data-id="4" v-bind="ifNotAdmin">Module</option>
        </select>
        <p class="m-0 d-inline-block">Need help getting started? <span class="fas fa-question-circle text-info"></span></p>
    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    export default {
        name: 'AddNewAssetSelect',
        props: [
            'isForbinAdmin'
        ],
        computed: {
            ifNotAdmin() {
                return {
                    disabled: !this.isForbinAdmin,
                    hidden: !this.isForbinAdmin
                }
            }
        },
        methods: {
            ...mapActions('assetManager/addModifyAsset', [
                'openAssetModal',
            ]),
            openAddModal(){
                //get the asset type
                let assetType = event.target.value;
                this.openAssetModal(assetType);
                //clear select value
                event.target.value = '';
            }
        }
    }
</script>