<template>
    <div class="pt-md-4">
        <h2 class="px-md-4 mb-4">Your Search Results...</h2>

        <!-- No Results Blurb -->
        <div class="p-4 text-center" v-if="showNoResults">
            <h4 class="text-light-red">Sorry. That search returned no results.</h4>
            <p class="mb-0">Please try your search again using the tips below.</p>
        </div>

        <div class="container" v-if="!showDefaultPage">
            <!-- Search Form -->
            <div class="mt-4 mx-auto w-md-50">
                <search-form :search-field="getSearchFieldValue" form-target="_self"></search-form>
            </div>

            <div class="nav-boxes pb-4 pb-sm-7 border-bottom-4 mb-2" :class="[activeTab == 'events' ? 'border-light-yellow' : activeTab == 'vendors' ? 'border-green' : 'border-light-purple']">
                <div
                    class="nav-box"
                    :class="{active: activeTab == 'events'}"
                    @click="activeTab = 'events'"
                >
                    <div class="bg-gray-op px-2 py-2 py-md-6 d-flex flex-sm-column align-items-center justify-content-center">
                        <p class="h3 text-font my-0 mx-1 text-center text-uppercase">{{ events.length }}</p>
                        <p class="h6 text-font my-0 mx-0 text-center text-uppercase lh-xs">Event <br>Results</p>
                    </div>
                    <p
                        class="btn mx-auto"
                        :class="[activeTab == 'events' ? 'btn-white' : 'btn-secondary']"
                    >
                        Display{{ activeTab == 'events' ? 'ing' : '' }} results
                    </p>
                </div>
                <div
                    class="nav-box"
                    :class="{active: activeTab == 'vendors'}"
                    @click="activeTab = 'vendors'"
                >
                    <div class="bg-secondary px-2 py-2 py-md-6 d-flex flex-sm-column align-items-center justify-content-center">
                        <p class="h3 text-primary my-0 mx-1 text-center text-uppercase">{{ vendorCount }}</p>
                        <p class="h6 text-primary my-0 mx-0 text-center text-uppercase lh-xs">Vendor <br>Results</p>
                    </div>
                    <p
                        class="btn btn-sm btn-block"
                        :class="[activeTab == 'vendors' ? 'btn-white' : 'btn-secondary']"
                    >
                        Display{{ activeTab == 'vendors' ? 'ing' : '' }} results
                    </p>
                </div>
                <div
                    class="nav-box"
                    :class="{active: activeTab == 'other'}"
                    @click="activeTab = 'other'"
                >
                    <div class="bg-gray-op px-2 py-2 py-md-6 d-flex flex-sm-column align-items-center justify-content-center">
                        <p class="h3 text-font my-0 mx-1 text-center text-uppercase">{{ other.length }}</p>
                        <p class="h6 text-font my-0 mx-0 text-center text-uppercase lh-xs">Other <br>Results</p>
                    </div>
                    <p
                        class="btn mx-auto"
                        :class="[activeTab == 'other' ? 'btn-white' : 'btn-secondary']"
                    >
                        Display{{ activeTab == 'other' ? 'ing' : '' }} results
                    </p>
                </div>
            </div>
            <div class="results">
                <div v-show="activeTab == 'events'">
                    <div class="row">
                        <template v-for="(result, index) in events">
                            <div
                                :key="'event-' + index"
                                class="col-lg-6"
                            >
                                <div class="py-2 py-md-4 px-md-4">
                                    <p class="font-weight-bold text-uppercase mb-1">{{ result.name }}</p>
                                    <p class="mb-1 text-truncate-multi" style="--ttmfs:16px;--ttmlh:1.2;--ttmlc:2;">{{ textFromHtml(result.text) }}</p>
                                    <div class="d-flex flex-wrap m-n1 result-btns">
                                        <a class="btn btn-sm btn-secondary m-1" :href="result.url" :title="`Opens ${result.name}'s page in the same window`">View Page</a>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div v-show="activeTab == 'vendors'">
                    <div class="d-flex align-items-center justify-content-between flex-wrap pt-md-4 px-md-4 m-n1">
                        <vue-select
                            name="Filter by Category"
                            :options="vendorCatOptions"
                            v-model="vendorCat"
                            style="flex: 0 1 200px;"
                            class="m-1"
                        ></vue-select>
                        <div
                            :class="[sortDir == 'down' ? 'text-primary' : 'text-quaternary']"
                            class="cursor-pointer m-1"
                            @click="sortDir = sortDir ? 'up' : 'down'"
                            v-show="sortDir !== 'up'"
                        ><span class="fas fa-lg fa-sort-alpha-down"></span></div>
                        <div
                            :class="[sortDir == 'up' ? 'text-primary' : 'text-quaternary']"
                            class="cursor-pointer m-1"
                            @click="sortDir = sortDir ? 'down' : 'up'"
                            v-show="sortDir == 'up'"
                        ><span class="fas fa-lg fa-sort-alpha-up"></span></div>
                    </div>
                    <a href="#" class="px-md-4 m-0" @click.prevent="sortDir = ''; vendorCat = '';" style="user-select: none;"><small>Reset Filters</small></a>
                    <div class="row">
                        <template v-for="(result, index) in computedVendors">
                            <div
                                v-if="result.id"
                                :key="'vendor-' + index"
                                class="col-lg-6"
                            >
                                <div class="py-2 py-md-4 px-md-4">
                                    <p class="font-weight-bold text-uppercase mb-1">{{ result.business_name }}</p>
                                    <p class="mb-1 text-truncate-multi" style="--ttmfs:16px;--ttmlh:1.2;--ttmlc:2;">{{ textFromHtml(result.company_info) }}</p>
                                    <div class="d-flex flex-wrap m-n1 result-btns">
                                        <p class="btn btn-sm btn-secondary m-1 cursor-pointer" :title="`Opens ${result.business_name}'s overview modal`" @click="openQuickView(result)">Quick View</p>
                                        <a class="btn btn-sm btn-secondary m-1" :href="'/vendors/' + result.url" :title="`Opens ${result.business_name}'s page in the same window`">Detailed View</a>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div v-show="activeTab == 'other'">
                    <div class="row">
                        <template v-for="(result, index) in other">
                            <div
                                :key="'other-' + index"
                                class="col-lg-6"
                            >
                                <div class="py-2 py-md-4 px-md-4">
                                    <p class="font-weight-bold text-uppercase mb-1">{{ result.name }}</p>
                                    <p class="mb-1 text-truncate-multi" style="--ttmfs:16px;--ttmlh:1.2;--ttmlc:2;">{{ textFromHtml(result.text) }}</p>
                                    <div class="d-flex flex-wrap m-n1 result-btns">
                                        <a class="btn btn-sm btn-secondary m-1" :href="result.url" :title="`Opens our ${result.name} page in the same window`">View Page</a>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="quick-view-modal" v-if="showQuickView">
                <div class="qvm-background" @click="hideQuickView"></div>
                <div class="qvm-body">
                    <div class="qvmb-left">
                        <div class="top-bar" :class="{'with-right': currentVendor.reviews && currentVendor.reviews.length}">
                            <div class="contact-bar">
                                <template v-if="user">
                                    <template v-if="!isOwnPage">
                                        <vue-link link-icon="fas fa-envelope" :link-title="`Send a message to ${currentVendor.business_name}`" @click.prevent="openMessenger(currentVendor.messenger_id)"></vue-link>
                                        <vue-link link-icon="fas fa-phone" :link-path="`tel:${vendorPhoneLink}`" :link-title-name="currentVendor.phone" v-if="currentVendor.phone"></vue-link>

                                        <template v-if="isSpouse">
                                            <vue-link
                                                v-show="currentVendor.is_queued"
                                                link-icon="fas fa-heart text-muted"
                                                link-title="To add this vendor to your queue, click here to find them in the Vendor List and choose the appropriate category"
                                                link-path="/portal/dashboard/user/your-vendors" link-target="_blank"
                                            ></vue-link>
                                            <vue-link
                                                v-show="!currentVendor.is_queued"
                                                link-icon="fas fa-heart"
                                                link-title="To remove this vendor from your queue, click here to find them in the Vendor List and choose the appropriate category"
                                                link-path="/portal/dashboard/user/your-vendors"
                                                link-target="_blank"
                                            ></vue-link>

                                            <vue-link
                                                v-show="!hasConfirmedStatus"
                                                link-class="text-decoration-none"
                                                link-title="To request confirmation of services from this vendor, click here to find them in the Vendor List and choose the appropriate category"
                                                link-icon="fas fa-check-circle"
                                                link-path="/portal/dashboard/user/your-vendors"
                                                link-target="_blank"
                                            ></vue-link>
                                            <vue-link
                                                v-show="hasConfirmedStatus"
                                                link-class="text-decoration-none"
                                                link-title="To view the status of confirmation, click here to find them in the Vendor List within the appropriate category"
                                                link-path="/portal/dashboard/user/your-vendors"
                                                link-icon="fas fa-check-circle text-muted"
                                                link-target="_blank"
                                            ></vue-link>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <vue-link link-title="By clicking this, other users will be able to start a conversation with you in your communication hub" link-icon="fas fa-envelope"></vue-link>
                                        <vue-link link-icon="fas fa-phone" :link-path="`tel:${vendorPhoneLink}`" :link-title-name="currentVendor.phone" v-if="currentVendor.phone"></vue-link>
                                        <vue-link link-title="By clicking this, spouses will be able to add you to their queue" link-icon="fas fa-heart"></vue-link>
                                        <vue-link link-title="This will show up as active if you have confirmed the spouses that have sent you a confirmation request" link-icon="fas fa-check-circle"></vue-link>
                                    </template>
                                </template>
                                <template v-else>
                                    <vue-link link-title="You must be logged in to use this feature" @click.prevent="showRegisterModal = true" link-icon="fas fa-envelope"></vue-link>
                                    <vue-link link-icon="fas fa-phone" :link-path="`tel:${vendorPhoneLink}`" :link-title-name="currentVendor.phone" v-if="currentVendor.phone"></vue-link>
                                    <vue-link link-title="You must be logged in to use this feature" @click.prevent="showRegisterModal = true" link-icon="fas fa-heart"></vue-link>
                                    <vue-link link-title="You must be logged in to use this feature" @click.prevent="showRegisterModal = true" link-icon="fas fa-check-circle"></vue-link>
                                </template>
                            </div>
                            <div class="close" @click="hideQuickView">
                                <span class="fas fa-times"></span>
                            </div>
                        </div>
                        <div class="mid-bar">
                            <div class="mt-2">
                                <img :src="currentVendor.logo_path || `/images/custom/site/icon-user.png`" :alt="`${currentVendor.business_name}'s Logo`">
                            </div>
                            <p class="h5 vendor-name text-center">{{ currentVendor.business_name }}</p>
                            <p class="mb-0 text-center" v-if="currentVendor.tagline">{{ currentVendor.tagline }}</p>
                            <p class="mb-2 text-center"><small>{{ currentVendor.city }}, {{ currentVendor.state }}</small></p> 
                            <div class="social-links">
                                <vue-link
                                    link-class="vlp-facebook"
                                    link-icon="fab fa-facebook"
                                    :link-path="formatUrl(currentVendor.facebook_url)"
                                    :link-title-name="`${currentVendor.business_name}'s Facebook page`"
                                    v-if="currentVendor.facebook_url"
                                ></vue-link>
                                <vue-link
                                    link-class="vlp-youtube"
                                    link-icon="fab fa-youtube"
                                    :link-path="formatUrl(currentVendor.youtube_url)"
                                    :link-title-name="`${currentVendor.business_name}'s YouTube page`"
                                    v-if="currentVendor.youtube_url"
                                ></vue-link>
                                <vue-link
                                    link-class="vlp-twitter"
                                    link-icon="fab fa-twitter"
                                    :link-path="formatUrl(currentVendor.twitter_url)"
                                    :link-title-name="`${currentVendor.business_name}'s Twitter page`"
                                    v-if="currentVendor.twitter_url"
                                ></vue-link>
                                <vue-link
                                    link-class="vlp-linkedin"
                                    link-icon="fab fa-linkedin"
                                    :link-path="formatUrl(currentVendor.linkedin_url)"
                                    :link-title-name="`${currentVendor.business_name}'s LinkedIn page`"
                                    v-if="currentVendor.linkedin_url"
                                ></vue-link>
                                <vue-link
                                    link-class="vlp-instagram"
                                    link-icon="fab fa-instagram"
                                    :link-path="formatUrl(currentVendor.instagram_url)"
                                    :link-title-name="`${currentVendor.business_name}'s Instagram page`"
                                    v-if="currentVendor.instagram_url"
                                ></vue-link>
                                <vue-link
                                    link-class="vlp-flickr"
                                    link-icon="fab fa-flickr"
                                    :link-path="formatUrl(currentVendor.flickr_url)"
                                    :link-title-name="`${currentVendor.business_name}'s Flickr page`"
                                    v-if="currentVendor.flickr_url"
                                ></vue-link>
                            </div>
                            <vue-link link-class="text-14" :link-path="formatUrl(currentVendor.website)" :link-text="currentVendor.website" :link-title-name="`${currentVendor.business_name}'s website`"></vue-link>
                        </div>
                        <div class="bot-bar mt-2 mx-n1">
                            <vue-link
                                v-show="isSpouse"
                                :link-text="quickViewButtonText"
                                link-class="btn btn-sm flex-grow-1 mx-1 btn-secondary"
                                :link-title="quickViewButtonTitle"
                                link-path="/portal/dashboard/user/your-vendors"
                                link-target="_blank"
                            ></vue-link>
                            <vue-link
                                v-if="currentVendor.url"
                                link-text="View full profile"
                                link-class="btn mx-auto btn-secondary"
                                :link-path="`/vendors/${currentVendor.url}`"
                                :link-title-name="`${currentVendor.business_name}'s Landing Page`"
                            ></vue-link>
                        </div>
                    </div>
                    <div class="qvmb-right" v-if="currentVendor.reviews && currentVendor.reviews.length">
                        <div class="header">
                            <p class="m-0">Latest customer reviews</p>
                            <div class="close" @click="hideQuickView">
                                <span class="fas fa-times"></span>
                            </div>
                        </div>
                        <div class="vlp-reviews">
                            <template v-for="(review, r_index) in getReviews(currentVendor.reviews)" >
                                <div class="vlp-review" :key="r_index">
                                    <p class="vlpr-review">{{ review.text }}</p>
                                    <p class="vlpr-from">
                                        <small>{{ getFrom(review) }}</small>
                                    </p>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Default Search Page -->
        <default-search v-else />
    </div>
</template>

<script>
import _ from 'lodash';
import VueLink from './../../Core/Global/Components/VueLink';
import DefaultSearch from './DefaultSearch';
import SearchForm from './SearchForm';
import VueSelect from './../../Core/Global/Components/VueForms/VueSelect';

export default {
    components: {
        VueLink,
        SearchForm,
        DefaultSearch,
        VueSelect
    },
    props: ["results", "user"],
    data() {
        return {
            activeTab: 'vendors',
            showQuickView: false,
            currentVendor: {},
            vendorCat: "",
            sortDir: ""
        }
    },
    computed: {
        events() {
            return this.results && this.results.events ? this.results.events : [];
        },
        computedVendors() {
            let vendors = _.cloneDeep(this.results && this.results.vendors ? this.results.vendors : []);

            if (this.sortDir) {
                if (this.sortDir == 'down') {
                    vendors.sort((a,b) => (a.business_name > b.business_name) ? 1 : -1);
                } else if (this.sortDir == 'up') {
                    vendors.sort((a,b) => (a.business_name < b.business_name) ? 1 : -1);
                }
            }

            if (this.vendorCat) {
                vendors = vendors.reduce((a, c) => {
                    if (c.categories.includes(this.vendorCat)) {
                        a.push(c);
                    }
                    return a;
                }, [])
            }

            return vendors;
        },
        vendorCatOptions() {
            let obj = [{name:"",value:"",hidden:true}];
            this.results.categories.forEach(cat => obj.push({name:cat,value:cat}));
            return obj;
        },
        other() {
            return this.results && this.results.other ? this.results.other : [];
        },
        quickViewButtonText() {
            return this.currentVendor && this.currentVendor.is_queued == 1 ? 'Remove from queue' : 'Add to queue';
        },
        quickViewButtonTitle() {
            return this.currentVendor && this.currentVendor.is_queued == 1 ? 'To remove this vendor from your queue, click here to find them in the Vendor List and choose the appropriate categor' : 'To add this vendor to your queue, click here to find them in the Vendor List and choose the appropriate category';
        },
        isOwnPage() {
            if (this.user) {
                return this.currentVendor.messenger_id == this.user.messenger_id;
            } else {
                return false;
            }
        },
        isSpouse() {
            return this.user && this.user.user_type == 'spouse_user';
        },
        vendorAddress() {
            return `${this.currentVendor.address_1}, ${this.currentVendor.address_2 ? this.currentVendor.address_2 + ', ' : ""}${this.currentVendor.city}, ${this.currentVendor.state} ${this.currentVendor.zip_code}`;
        },
        vendorPhoneLink() {
            return this.currentVendor.phone.replace(/(?!\d)./g,"");
        },
        hasConfirmedStatus(){
            if(this.currentVendor.is_confirmed || this.currentVendor.is_confirmed_pending || this.currentVendor.is_denied){
                return true;
            }

            return false;
        },
        vendorCount() {
            return this.computedVendors.reduce((acc,cur) => {
                return cur.id ? acc + 1 : acc;
            },0)
        },
        showDefaultPage(){
            return !this.qParamInUrl || this.areResultsEmpty ? true : false;
        },
        showNoResults(){
            return this.qParamInUrl && this.areResultsEmpty ? true : false;
        },
        qParamInUrl(){
            let urlParams = new URLSearchParams(window.location.search);

            return urlParams.has('q') ? true : false;
        },
        areResultsEmpty(){
            let whatToReturn = true;

            if(this.results.events.length > 0){
                whatToReturn = false;
            } else if(this.results.vendors.length > 0){
                whatToReturn = false;
            } else if(this.results.other.length > 0){
                whatToReturn = false;
            }

            return whatToReturn;
        },
        getSearchFieldValue(){
            let urlParams = new URLSearchParams(window.location.search);
            return this.qParamInUrl ? urlParams.get('q') : '';
        }
    },
    methods: {
        openQuickView(vendor) {
            this.currentVendor = _.cloneDeep(vendor);
            this.showQuickView = true;
            document.body.style.overflowY = 'hidden';

            axios.post('/portal/dashboard/vendor/your-leads/add-quick-views', {vendor_id: this.currentVendor.id})
                .catch(error => logApplicationError(error));
        },
        hideQuickView() {
            this.showQuickView = false;
            document.body.style.removeProperty('overflow-y');
        },
        getReviews(reviews) {
            return reviews;
        },
        getFrom(review) {
            let from = review.submitted_by;
            let on = review.created_at;

            return `-${from} on ${on}`;
        },
        openMessenger(id) {
            window.location.href = `/portal/dashboard/messenger?messenger_id=${id}`;
        },
        formatUrl(url) {
            return this.isValidUrl(url) ? url : this.isValidUrl(`https://${url}`) ? `https://${url}` : '';
        },
        isValidUrl(string) {
            try {
                new URL(string);
            } catch (_) {
                return false;
            }

            return true;
        },
        textFromHtml(html) {
            let doc = new DOMParser().parseFromString(html, 'text/html');
            return doc.body.textContent || "";
        }
    }
};
</script>

<style lang="scss" scoped>
    @use 'sass:math';
    @import '../../../sass/_vue-import.scss';

    .nav-boxes {
        display: flex;
        justify-content: center;
        align-items: stretch;
        transition: 100ms ease-out;

        @media only screen and (max-width: 600px) { flex-direction: column; }

        @include mobile {
            margin-right: -1rem;
            margin-left: -1rem;
        }
    }

    .nav-box {
        padding: 1rem;
        opacity: .5;
        transition: 100ms ease-out;
        position: relative;
        cursor: pointer;

        @include mobile { flex: 1 1 0; }

        &.active,
        &:hover {
            opacity: 1;
        }

        &:before {
            content: "";
            position: absolute;
            top: calc(100% - 1rem);
            left: 50%;
            transform: translate(-50%);
            border-style: solid;
            border-right-color: transparent !important;
            border-left-color: transparent !important;
            border-width: 0 25px 0 25px;
            transition: 200ms ease-out;
        }

        &.active:before { border-width: 25px 25px 0 25px; }

        &:nth-child(1):before { border-top-color: var(--light-yellow); }
        &:nth-child(2):before { border-top-color: var(--green); }
        &:nth-child(3):before { border-top-color: var(--light-purple); }

        p {
            @media only screen and (max-width: 600px) {
                br { display: none; }
            }
        }

        .btn { margin: 0; }

        @include not-mobile { flex: 0 0 math.div(100%, 4); }
    }

    .quick-view-modal {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .contact-bar {
        margin: -.5rem !important;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .vue-link {
            margin: .5rem !important;
            color: var(--tg-400);
            cursor: pointer;
            transition: 200ms ease-out;

            &:hover { color: var(--tg-500); }

            .fa-phone {
                color: var(--primary)!important;

                &:hover {
                    color: var(--black-blue)!important;
                }
            }
        }
    }

    .qvm-background {
        background-color: var(--brand-teal);
        opacity: .8;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }

    .quick-view-modal .qvm-body {
        position: relative;
        background-color: white;
        max-width: calc(100% - 2rem);
        max-height: calc(100vh - 2rem);
        display: flex;

        @include desktop {
            width: 800px;
            max-height: 60vh;
        }

        @include tablet {
            width: 80vw;
            max-height: 60vh;
        }

        @include mobile {
            flex-direction: column;
            overflow-y: auto;

            &::-webkit-scrollbar { width: 3px; }
            &::-webkit-scrollbar-track { background: var(--light-purple); } // track
            &::-webkit-scrollbar-thumb { background: var(--primary); }
            &::-webkit-scrollbar-thumb:hover { background: rgba(0,0,0,0.4); } // thumb on hover
        }

        .qvmb-right, .qvmb-left {
            flex: 1 1 0;
        }

        .qvmb-left {
            padding: 1rem;
            display: flex;
            flex-direction: column;
            align-items: stretch;

            @include not-mobile {
                overflow-y: auto;

                &::-webkit-scrollbar { width: 3px; }
                &::-webkit-scrollbar-track { background: var(--light-purple); } // track
                &::-webkit-scrollbar-thumb { background: var(--primary); }
                &::-webkit-scrollbar-thumb:hover { background: rgba(0,0,0,0.4); } // thumb on hover
            }

            .top-bar {
                display: flex;
                justify-content: space-between;

                &.with-right {
                    @include not-mobile {
                        justify-content: flex-end;

                        .close { display: none; }
                    }
                }

                .close { cursor: pointer; }
            }

            .mid-bar {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .vendor-name {
                    margin: 1rem 0;
                    padding-bottom: 1rem;
                    position: relative;

                    &:before {
                        content: "";
                        position: absolute;
                        left: 50%;
                        bottom: 0;
                        transform: translate(-50%, 50%);
                        height: 3px;
                        width: 50%;
                        background-color: #ddd;
                    }
                }
            }

            .bot-bar {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            img {
                max-width: 150px;
                max-height: 150px;
            }
        }

        .qvmb-right {
            background-color: #f0f0f0;
            display: flex;
            flex-direction: column;

            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 1rem;
            }

            .close {
                cursor: pointer;

                @include not-mobile { display: block; }
                @include mobile { display: none; }
            }
        }
    }

    .social-links {
        display: flex;

        .vue-link {
            @include rem(24px);
            margin: .5rem;
            line-height: 1;
        }

        .vlp-facebook { color: map-get($social-media-colors, 'facebook') }
        .vlp-twitter { color: map-get($social-media-colors, 'twitter') }
        .vlp-instagram { color: black }
        .vlp-youtube { color: map-get($social-media-colors, 'youtube') }
        .vlp-linkedin { color: map-get($social-media-colors, 'linkedin') }
        .vlp-flickr { color: #0063DC; }
    }

    $review-border-radius: 10;

    .vlp-reviews {
        margin: 0;
        padding: 0 1rem;
        overflow-y: auto;

        &::-webkit-scrollbar { width: 3px; }
        &::-webkit-scrollbar-track { background: var(--light-purple); } // track
        &::-webkit-scrollbar-thumb { background: var(--primary); }
        &::-webkit-scrollbar-thumb:hover { background: rgba(0,0,0,0.4); } // thumb on hover

        .vlp-review {
            padding: 1rem 0;
            display: grid;
            grid-template:
                "review review" max-content
                ". from" max-content
                / 1fr auto;

            .vlpr-review,
            .vlpr-from {
                @include rem(14px);
                margin: 0;
            }

            .vlpr-review {
                grid-area: review;
                background-color: var(--secondary);
                color: black;
                padding: 8px 16px;
                border-radius: #{$review-border-radius}px;
                z-index: 1;
            }

            .vlpr-from {
                grid-area: from;
                background-color: var(--primary);
                color: white;
                padding: #{$review-border-radius + 4}px 8px 4px;
                margin-top: -#{$review-border-radius}px;
                border-radius: 0 0 #{$review-border-radius}px #{$review-border-radius}px;
            }
        }
    }

    .disabled {
        opacity: .6;
        cursor: default !important;
    }

    .result-btns .btn {
        @include mobile {
            flex-grow: 1;
        }
    }
</style>