<template>
    <div class="comments-form">
        <form id="add-comment-form" name="add-comment-form" @submit.prevent="onSubmit">
            <span class="text-muted text-14 mb-1 d-block">All fields marked with * are required</span>

            <vue-textarea
                name="Leave a comment"
                v-model="activeRecord.comment"
                required
            ></vue-textarea>

            <div class="row">
                <div class="col-md-6">
                    <vue-input
                        name="Name"
                        v-model="activeRecord.name"
                        required
                    ></vue-input>
                </div>

                <div class="col-md-6">
                    <vue-input
                        name="Email"
                        v-model="activeRecord.email"
                        required
                    ></vue-input>
                </div>
            </div>

            <input
                name="post_id"
                :value="activeBlog"
                type="hidden"
                required
            />

            <vue-hcaptcha v-if="hcaptchaSiteKey"
                :sitekey="hcaptchaSiteKey"
                @verify="onVerify"
                @expired="onExpire"
                @error="onError"
            />

            <vue-button
                @click.prevent="onSubmit"
                :class="{disabled:isDisabled}"
                :disabled="isDisabled"
            >Submit Comment</vue-button>
        </form>

        <vue-modal :visible="successModalVisible" @on-close="closeModal">
            <template #header>
                <p class="h2 m-0 text-success" v-if="response == 'Success'"><span class="fas fa-check mr-1"></span>{{ response }}</p>
                <p class="h2 m-0" v-else>{{ response }}</p>
            </template>
            <p class="m-0">{{ message }}</p>
            <div id="form-errors" class="form-errors mb-0"></div>
        </vue-modal>
    </div>
</template>

<script>
    import { mapState, mapMutations, mapActions } from 'vuex';
    import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

    export default {
        components: { VueHcaptcha },
        data() {
            return {
                verified: false,
                expired: false,
                token: null,
                eKey: null,
                error: null,
            }
        },
        computed: {
            ...mapState('blog', [
                'activeBlog',
                'hcaptchaSiteKey',
            ]),
            ...mapState('comment/list', [
                'activeRecord',
                'successModalVisible',
                'message',
                'response',
            ]),
            isDisabled: function(){
                return !this.verified;
            }
        },
        methods: {
            ...mapMutations('comment/list', [
                'SET_POST_ID'
            ]),
            ...mapActions('comment/list', [
                'submitForm',
                'closeModal',
                'setPostId'
            ]),
            onVerify(token, ekey) {
                this.verified = true;
                this.token = token;
                this.activeRecord.hcaptchaToken=token;
                this.activeRecord.hcaptchaEkey=ekey;
                this.eKey = ekey;
            },
            onExpire() {
                this.verified = false;
                this.token = null;
                this.eKey = null;
                this.expired = true;
            },
            onError(err) {
                this.token = null;
                this.eKey = null;
                this.error = err;
            },
            onSubmit() {
                this.SET_POST_ID(this.activeBlog);
                this.submitForm();
            }
        }
    }
</script>