<template>
    <button type="button" class="btn btn-primary m-0" @click.prevent="openModal">Add Tag</button>
</template>
<script>
    import { mapActions } from 'vuex';
    export default {
        name: 'AddNewTagButton',
        methods: {
            ...mapActions('tag/addModifyTag', [
                'openModal',
                'closeModal'
            ])
        }
    }
</script>