<template>
    <div id="blog-module">
        <div class="blog-layout">
            <div class="blog-left">
                <template v-if="!blogs.isLoading">
                    <SiteBlogDetails v-if="pageType == 'post'" />
                    <SiteBlogList v-else/>
                </template>
                <vue-loader v-else></vue-loader>
            </div>

            <div class="blog-sidebar">
                <!-- For when anything more than the author name is implemented -->
                <!-- <SiteBlogSidebarAuthorBlurb v-if="activeBlog"/> -->
                <SiteBlogSidebarFeaturedList />
                <SiteBlogSidebarFilters />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'SiteBlogModuleIndex',
    props: [
        "data",
        "recent",
        "pageType",
        "featured",
        "archives",
        "categories",
        "captchaKey"
    ],
    computed: {
        ...mapState('blog', [
            'blogs',
            'activeBlog',
        ])
    },
    methods: {
        ...mapActions('blog', [
            'setData',
            'initSSFP',
        ])
    },
    created(){
        let data = this.data ? this.data : null;
        this.initSSFP([this.pageType, data]);

        this.setData(['categories', this.categories]);
        this.setData(['archives', this.archives]);
        this.setData(['featured', this.featured]);
        this.setData(['pageType', this.pageType]);
        this.setData(['recent', this.recent]);
        this.setData(['hcaptchaSiteKey', this.captchaKey]);

        if(this.data){
            this.setData(['activeRecord', this.data]);
        }
    }
};
</script>

<style lang="scss" scoped>
    @use 'sass:math';
    @import '../../../../../../sass/_vue-import.scss';

    .blog-layout {
        display: flex;
        margin: 1rem -1rem;

        @include desktop {
            .blog-left { flex: 1 1 math.div(200%, 3); }
            .blog-sidebar {
                max-width: 350px;
                flex: 1 1 math.div(100%, 3);
            }
        }

        @include not-desktop {
            flex-direction: column;
        }

        &::v-deep .disabled {
            opacity: .4;
            pointer-events: none;
        }

        .blog-left, .blog-sidebar { margin: 1rem; }
    }
</style>

<style>
    #at-custom-sidebar { display: none; }
</style>