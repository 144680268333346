<template>
    <div class="comment-list-item">
        <div class="top">
            <p class="name"><span class="fas fa-user"></span>{{ comment.name }}</p>
            <p class="date" v-html="formattedDate()"></p>
        </div>
        <p class="comment">{{ comment.comment }}</p>
        <!-- Reply functionality if it's ever included -->
        <!-- <div class="reply" v-if="index == 1">
            <div class="top">
                <p class="name"><span class="fas fa-user"></span>Name {{ index }}</p>
                <p class="date"><span class="fas fa-calendar"></span>May 31st, 2021</p>
            </div>
            <p class="reply-comment">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div> -->
    </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
    props:['comment'],
    methods: {
        formattedDate() {
            let date = dayjs(this.comment.created_at).format("MMMM D, YYYY");
            return `<span class="fas fa-calendar"></span>${date}`;
        }
    }
}
</script>

<style lang="scss" scoped>
    .comment-list-item {
        margin: 1rem 0;
        padding: 1rem;
        background-color: hsl(0deg,0%,95%);
        border-radius: 5px;
        overflow: hidden;
        font-size: 14px;

        .top {
            display: flex;
            flex-wrap: wrap;
            margin: -.25rem -.5rem;
            font-size: 14px;

            .name, .date {
                margin: .25rem .5rem;
                display: flex;
                align-items: center;
                line-height: 1;

                &::v-deep svg { margin-right: .5rem; }
            }
        }

        .comment, .reply-comment { margin: 1rem 0 0; }

        .reply {
            padding: 1rem;
            margin: 1rem -1rem -1rem;
            background-color: hsl(0deg,0%,90%);
            box-shadow: inset 0px 5px 10px -10px;
        }
    }
</style>