<template>
    <vue-modal :visible="isModalOpen" @on-close="closeRecycleModal" id="category-recycle-modal">
        <template #header>
            <h5 class="modal-title"><span class="fas fa-trash mr-1 text-tertiary"></span>Confirm Category Delete</h5>
        </template>

        <div class="d-flex flex-column align-items-center">
            <span class="fas fa-exclamation-triangle text-danger fa-7x"></span>
            <p class="h4 font-weight-bold">Are you sure you want to delete this category?</p>
            <p>This action is permanent. Consider marking the category as declined.</p>
        </div>

        <template #footer>
            <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-outline-primary cancel-btn" @click="closeRecycleModal">Cancel</button>
                <button type="button" class="btn btn-primary confirm-btn ml-2" @click="recycleCategory" :disabled="isModalProcessing">{{ isModalProcessing ? 'Processing...' : 'Delete category' }}</button>
            </div>
        </template>
    </vue-modal>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: 'CategoryRecycleModal',
        computed: {
            ...mapState('category', {
                activeRecord: state => state.addModifyCategory.activeRecord,
                isModalOpen: state => state.categoryRecycleModal.isModalOpen,
                isModalProcessing: state => state.categoryRecycleModal.isModalProcessing,
            })
        },
        methods: {
            ...mapActions('category', {
                closeModal: 'categoryRecycleModal/closeModal',
                resetCategoryModal: 'addModifyCategory/resetCategoryModal',
                recycleRecord: 'categoryRecycleModal/recycleRecord',
            }),
            closeRecycleModal(){
                this.resetCategoryModal();
                this.closeModal();
            },
            recycleCategory(){
                this.recycleRecord({
                    ajaxRoute: `/vpanel/modules/blog/category/destroy/${this.activeRecord.id}`,
                    recordId: this.activeRecord.id,
                    dispatchAction: 'category/list/removeRecordFromDataTable'
                });
                this.resetCategoryModal();
            }
        }
    }
</script>