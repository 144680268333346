<template>
    <div class="blog-details">
        <div class="blog-top-bar">
            <p class="blog-title">{{ blog.title }}</p>
            <button class="go-back" @click="closeBlogDetails()"><span class="fas fa-chevron-left"></span>Back to List</button>
        </div>

        <div class="blog-details">
            <div class="blog-author-image"></div> <!-- I guess an author image would be added here??? -->
            <div class="blog-info">
                <p class="blog-date" v-html="formattedDate()"></p>
                <p class="blog-author"><span class="fas fa-user"></span>{{ blog.author_name }}</p>
                <p
                    class="category"
                    v-for="catId in blog.categories"
                    :key="'category_' + catId"
                    @click="filterBlogs({type: 'categories', id: catId});closeBlogDetails()"
                ><span class="fas fa-box"></span>{{ categories[catId].name }}</p>
            </div>
        </div>

        <div class="blog-image">
            <img :src="blog.featured_image" :alt="blog.featured_image_alt" />
        </div>

        <div class="content" v-html="blog.content"></div>

        <div class="blog-tags">
            <p
                class="tag"
                v-for="tagId in blog.tags"
                :key="'tag_' + tagId"
                @click="filterBlogs({type: 'tags', id: tagId});closeBlogDetails()"
            ><span class="fas fa-tag"></span>{{ tags[tagId].name }}</p>
        </div>
        <button class="go-back" @click="closeBlogDetails()"><span class="fas fa-chevron-left"></span>Back to List</button>
        <!-- Go to www.addthis.com/dashboard to customize your tools -->
        <div class="addthis_inline_share_toolbox_llow"></div>

        <frontend-blog-comments
            v-if="blog.allow_comments"
            :blog="blog"
        ></frontend-blog-comments>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';

export default {
    computed: {
        ...mapState('blog', [
            'blogs',
            'tags',
            'categories',
            'activeBlog',
            'initialDescription',
            'initialKeywords',
            'initialTitle',
        ]),
        blog() {
            return this.blogs[this.activeBlog];
        }
    },
    methods: {
        ...mapActions('blog', [
            'closeBlogDetails',
            'filterBlogs'
        ]),
        formattedDate() {
            let date = dayjs(this.blog.scheduled_at).format("MMMM D, YYYY");
            return `<span class="fas fa-calendar"></span>${date}`;
        },
    }
}
</script>

<style lang="scss" scoped>
    @import '../../../../../../sass/_vue-import.scss';

    %badge {
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: .25rem .5rem;
        padding: .25rem .5rem;
        background-color: hsl(0deg,0%,95%);
        color: hsl(0deg,0%,15%);
        line-height: 1;
        cursor: pointer;
        border-radius: 3px;

        svg {
            margin-right: .25rem;
            font-size: 10px;
        }
    }

    .blog-details { margin: 0 0 2rem; }

    .blog-top-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 1rem;

        .blog-title {
            @include fluid-size(18px, 32px, font-size);
            font-weight: bold;
            margin: 0;
        }
    }

    .go-back {
        background: none;
        outline: none;
        border: none;
        padding: 0;
        font-weight: bold;

        svg { margin-right: .5rem; }
    }

    .blog-info {
        display: flex;
        flex-wrap: wrap;
        margin: -.25rem -.5rem;
        font-size: 14px;

        .blog-date, .blog-author {
            margin: .25rem .5rem;
            display: flex;
            align-items: center;
            line-height: 1;

            &::v-deep svg { margin-right: .5rem; }
        }

        .category {
            @extend %badge;
            background-color: hsl(0deg,0%,85%);
        }
    }

    .blog-image img { max-width: 100%; }
    .content { margin-top: 1rem; }

    .blog-tags {
        display: flex;
        flex-wrap: wrap;
        font-size: 12px;
        margin: -.25rem -.5rem;
        padding: 1rem 0;

        .tag { @extend %badge; }
    }

    .addthis_inline_share_toolbox_llow {
        margin-top: 1.5rem;
    }
</style>