<template>
    <vue-modal :visible="isModalOpen" @on-close="closeCategoryAddModifyModal" id="category-add-modify-modal">
        <template #header>
            <h5 class="modal-title"><span class="fas fa-box mr-1 text-tertiary"></span>&nbsp;{{ getModalHeader }}</h5>
        </template>

        <add-modify-Category-form
            :class="{'d-none': isFormProcessing}"
            :is-action-add="isActionAdd"
            :active-record="activeRecord"
            @submitForm="submitForm"
            @closeModal="closeModal"
        />

        <vpanel-loader v-if="isFormProcessing" />

        <template #footer>
            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-outline-primary cancel-button" @click.prevent="closeModal">Cancel</button>
                <button type="submit" class="btn btn-primary ml-1" :disabled="isFormProcessing" form="add-modify-category-form">{{ isFormProcessing ? 'Processing...' : 'Apply' }}</button>
            </div>
        </template>
    </vue-modal>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        name: 'AddModifyCategoryModal',
        computed: {
            ...mapState('category/addModifyCategory', {
                isModalOpen: state => state.isModalOpen,
                activeRecord: state => state.activeRecord,
                isActionAdd: state => state.formActionIsAdd,
                isFormProcessing: state => state.isFormProcessing,
            }),

            getModalHeader(){
                let action = this.isActionAdd ? 'Add a New ' : 'Modify ';

                return action + 'Category';
            }
        },
        methods: {
            ...mapActions('category/addModifyCategory', [
                'submitForm',
                'closeModal',
               'resetCategoryModal',

            ]),
            closeCategoryAddModifyModal(){
            let vm = this;
            vm.closeModal();

            setTimeout(function(){vm.resetCategoryModal()}, 200);
            }


        }
    }
</script>