<template>
    <div class="social-links">
        <vue-link v-if="facebook" link-icon="fab fa-facebook" link-path="facebook" link-title-name="our facebook page" />
        <vue-link v-if="twitter" link-icon="fab fa-twitter" link-path="twitter" link-title-name="our twitter page" />
        <vue-link v-if="linkedin" link-icon="fab fa-linkedin" link-path="linkedin" link-title-name="our linkedin page" />
        <vue-link v-if="instagram" link-icon="fab fa-instagram" link-path="instagram" link-title-name="our instagram page" />
        <vue-link v-if="pinterest" link-icon="fab fa-pinterest" link-path="pinterest" link-title-name="our pinterest page" />
        <vue-link v-if="youtube" link-icon="fab fa-youtube" link-path="youtube" link-title-name="our youtube page" />
        <vue-link v-if="vimeo" link-icon="fab fa-vimeo" link-path="vimeo" link-title-name="our vimeo page" />
    </div>
</template>

<script>
    export default {
        props: [
            "facebook",
            "twitter",
            "linkedin",
            "instagram",
            "pinterest",
            "youtube",
            "vimeo",
        ]
    }
</script>

<style>

</style>