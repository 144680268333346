<template>
    <div class="header-template-3" :data-variant="variant">
        <div class="top-bar">
            <div class="dynamic-container">
                <div class="top">
                    <vue-site-search
                        :search-template="searchTemplate"
                        v-bind="{...$props}"
                    ></vue-site-search>
                    <div class="links" v-if="primaryLinks || primaryCta">
                        <div class="primary-links" v-if="primaryLinks">
                            <vue-link
                                v-for="link in primaryLinks"
                                :key="link.text"
                                :link-obj="link"
                            ></vue-link>
                        </div>
                        <div class="ctas" v-if="primaryCta">
                            <vue-link v-if="primaryCta" :link-obj="primaryCta"></vue-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-bar">
            <div class="dynamic-container">
                <div class="bottom">
                    <div class="logo">
                        <img :src="logoPath" alt="Company logo">
                        <vue-link link-path="/" link-text="Company name home" link-class="absolute-fill text-throw"></vue-link>
                    </div>
                    <div class="d-flex align-items-center">
                        <vue-nav
                            :mobile-expanded="mobileNavExpanded"
                            v-bind="{...$props}"
                            :additional-links="combineLinksAndCtas"
                            @open-mobile-menu="openMobileMenu()"
                            @close-mobile-menu="closeMobileMenu()"
                        ></vue-nav>
                        <div class="mobile-controls">
                            <p class="open-mobile-menu" @click="openMobileMenu()">
                                <span class="fas fa-bars"></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { themingMixin } from '../../../Mixins/themingMixin';
import { navMixin } from '../../../Mixins/navMixin';
import { headerMixin } from '../../../Mixins/headerMixin';

export default {
    mixins: [
        themingMixin,
        navMixin,
        headerMixin
    ],
    props: [
        "designConfig",
        "primaryCta",
        "secondaryCta",
    ],
    computed: {
        combineLinksAndCtas() {
            let newArray = [];
            newArray.push(this.primaryCta, this.secondaryCta);
            newArray.concat(this.primaryLinks, this.secondaryLinks);
            return newArray;
        }
    },
    methods: {
        addVars() {
            document.body.style.setProperty('--hd-height', this.$el.offsetHeight + 'px');
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.addVars();
        })
        window.addEventListener('resize', () => {
            this.addVars();
        });
    },
    updated() {
        this.addVars();
    }
}
</script>

<style lang="scss">
    @import '../../../../../../sass/vue-import';
    $theme-color: var(--theme-color, 255, 255, 255);
    $theme-complement: var(--theme-complement, 0, 0, 0);
    $theme-complement-compliant: var(--theme-complement-compliant, 255, 255, 255);
    $border-radius: 20px; // setting statically for now while we figure out why variables aren't making it into the vue import

    body[data-header-template="3"] {
        section.page-title {
            grid-area: header-start / header-start / pagetitle-end / pagetitle-end;
            padding: calc(var(--hd-height) + 2rem) 0 2rem;
            margin: 0;
            max-width: 100%;
        }

        #vue-header {
            position: sticky;
            top: 0;
        }
    }
</style>

<style lang="scss" scoped>
    @import '../../../../../../sass/vue-import';
    $theme-color: var(--theme-color, 255, 255, 255);
    $theme-complement: var(--theme-complement, 0, 0, 0);
    $border-radius: 20px; // setting statically for now while we figure out why variables aren't making it into the vue import

    .header-template-3 {

        // Default container variant
        &:not([data-variant]) .dynamic-container {
            max-width: var(--container-width, 1140px);
            margin-left: auto;
            margin-right: auto;
            padding-left: calc((var(--grid-gutter-width, 30px) / 2));
            padding-right: calc((var(--grid-gutter-width, 30px) / 2));
        }

        // Full width variant
        &[data-variant="full"] .dynamic-container {
            padding-left: calc((var(--grid-gutter-width, 30px) / 2));
            padding-right: calc((var(--grid-gutter-width, 30px) / 2));
        }

        .top-bar {
            position: relative;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: rgb($theme-color);
                opacity: .8;
            }
        }

        .top {
            position: relative;
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            margin: 0 -1rem;

            .vue-site-search-2 {
                flex-grow: 1;
                padding: 0 1rem;

                .vss-input-group { width: 100%; }

                .search-icon { color: rgb($theme-complement); }

                input[type="search"] {
                    border-radius: 0;
                    width: 100%;
                    font-size: 14px;
                    background-color: rgb($theme-color);
                    color: rgb($theme-complement);

                    &::placeholder {
                        text-transform: uppercase;
                        color: rgba($theme-complement, .5);
                    }
                }
            }

            .links {
                padding: .25rem 1rem;
                display: flex;
                align-items: center;

                .ctas { padding: 0 1rem; }
            }
        }

        .bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .logo {
            position: relative;
            background-color: white;
            border-radius: 0 0 $border-radius $border-radius;
            @include fluid-size(8px, 16px, padding);
            @include fluid-size(80px, 125px, height);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;

            img {
                max-width: 100%;
                max-height: 100%;
                vertical-align: bottom;
            }
        }

        .mobile-controls {
            @include desktop { display: none; }

            .open-mobile-menu {
                color: rgb($theme-complement);
                font-size: 24px;
                margin: 0 0 0 1rem;
            }
        }

        .primary-links,
        .secondary-links {
            display: flex;

            a {
                color: rgb($theme-complement);
                padding: .25rem 1rem;
            }
        }
    }
</style>