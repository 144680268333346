<template>
    <div class="blog-list-item" :class="{featured:featured}">
        <div class="blog-wrapper">
            <div class="blog-image">
                <img :src="featured ? blog.featured_image : blog.thumbnail_image" :alt="featured ? blog.featured_image_alt : blog.thumbnail_image_alt" />
            </div>
            <div class="blog-details">
                <div class="blog-info">
                    <p class="blog-date" v-html="formattedDate"></p>
                    <template v-if="!featured">
                        <p class="blog-author"><span class="fas fa-user"></span>{{blog.author_name}}</p>
                        <template v-if="blog.categories.length">
                            <a
                                class="category"
                                :key="'bli-cat_' + catId"
                                v-for="catId in blog.categories"
                                :href="categories[catId].permalink"
                                :title="`Internal link to the ${categories[catId].name} category in the same window.`"
                            >
                                <span class="fas fa-box"></span>
                                {{ categories[catId].name }}
                            </a>
                        </template>
                    </template>
                </div>

                <div class="blog-preview" v-if="!featured">
                    <div class="title">
                        <a
                            :href="blog.permalink"
                            :title="`Internal link to the ${blog.title} post in the same window.`"
                        >
                            {{ blog.title }}
                        </a>
                    </div>
                    <div class="content" v-html="blog.content"></div>
                    <div class="blog-tags">
                        <a
                            class="tag"
                            :href="tagPermalink"
                            :key="'bli-tag_' + tagName"
                            v-for="(tagPermalink, tagName) in blog.tags"
                            :title="`Internal link to the ${tagName} tag in the same window.`"
                        >
                            <span class="fas fa-tag"></span>{{ tagName }}
                        </a>
                    </div>

                    <a
                        class="read-more"
                        :href="blog.permalink"
                        :title="`Internal link to the ${blog.title} post in the same window`"
                    >Read full post <span class="fas fa-chevron-right"></span></a>
                </div>
            </div>
            <div class="blog-preview" v-if="featured">
                <div class="title">
                    <a
                        :href="blog.permalink"
                        :title="`Internal link to the featured ${blog.title} post in the same window`"
                    >
                        {{ blog.title }}
                    </a>
                </div>
                <div class="content mb-1" v-html="blog.content"></div>
                <a
                    class="read-more"
                    :href="blog.permalink"
                    :title="`Internal link to the featured ${blog.title} post in the same window`"
                >
                    Read full post <span class="fas fa-chevron-right"></span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapState } from 'vuex';

export default {
    name: "SiteBlogListItem",
    props: [
        "id",
        "blog",
        "featured"
    ],
    computed: {
        ...mapState('blog', [
            'categories'
        ]),
        formattedDate() {
            let date = dayjs(this.blog.scheduled_at),
                twoDigitDay = date.format("DD"),
                monthShort = date.format("MMM");

            return this.featured ? `${twoDigitDay}<span>${monthShort}</span>` : `<span class="fas fa-calendar"></span>${this.blog.scheduled_at}`;
        }
    }
};
</script>

<style lang="scss" scoped>
    @import '../../../../../../sass/_vue-import.scss';

    @mixin fha($parent-width, $min, $max: 100%) {
        min-width: $min;
        max-width: $max;
        flex-basis: calc(#{$parent-width} * 999 - 100% * 999);
    }

    %badge {
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: .25rem .5rem;
        padding: .25rem .5rem;
        background-color: hsl(0deg,0%,95%);
        color: hsl(0deg,0%,15%);
        line-height: 1;
        cursor: pointer;
        border-radius: 3px;
        transition: 200ms ease-out;

        &:hover {
            background-color: rgb(var(--blog-theme-primary));
            color: rgb(var(--blog-theme-primary-compliant));
        }

        svg {
            margin-right: .25rem;
            font-size: 10px;
        }
    }

    .blog-list-item {
        font-size: 14px;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid hsl(0deg,0%,95%);
        margin-bottom: 1.5rem;

        &:last-child {
            padding-bottom: 0;
            border-bottom: 0;
            margin-bottom: 0;
        }

        .blog-wrapper {
            display: flex;
            flex-wrap: wrap;
            margin: -.5rem;
        }

        .blog-image {
            @include fha(500px, clamp(150px, 25%, 300px));
            margin: .5rem;
            z-index: 0;
            min-height: 200px;
            position: relative;

            img {
                position: absolute;
                top: 0;
                left: 0;
                object-fit: cover;
                width: 100%;
                height: 100% !important;
            }
        }

        .blog-details {
            @include fha(500px, 50%);
            flex-grow: 1;
            margin: .5rem;

            .blog-info {
                display: flex;
                flex-wrap: wrap;
                margin: -.25rem -.5rem;
                padding-bottom: 1rem;

                .blog-date, .blog-author {
                    margin: .25rem .5rem;
                    display: flex;
                    align-items: center;
                    line-height: 1;

                    &::v-deep svg { margin-right: .5rem; }
                }

                .category {
                    @extend %badge;
                    text-decoration: none;
                    background-color: hsl(0deg,0%,85%);
                }
            }

        }

        .blog-preview {
            grid-area: p;

            .title {
                font-size: 24px;
                font-weight: bold;
                margin-bottom: .5rem;
                cursor: pointer;

                a { text-decoration: none; }

                &:hover { text-decoration: underline; }
            }

            .content {
                @include truncate(14px,1.5,3);

                &::v-deep * {
                    font-size: 14px;
                    margin: 0;

                    &:last-child { margin-bottom: 0; }
                    &:first-child { margin-top: 0; }
                }
            }

            .blog-tags {
                display: flex;
                flex-wrap: wrap;
                font-size: 12px;
                margin: -.25rem -.5rem;
                padding: 1rem 0;

                .tag {
                    @extend %badge;
                    text-decoration: none;
                }
            }

            .read-more {
                background: none;
                outline: none;
                border: none;
                padding: 0;
                font-weight: bold;
                color: rgb(var(--blog-theme-primary));

                svg { margin-left: .5rem; }
            }
        }

        &.featured {
            .blog-wrapper {
                margin: 0;
                display: grid;
                grid-gap: 0;
                grid-template:
                    "d ." 1fr
                    ". p" max-content
                    / clamp(150px, 25%, 300px) 1fr;
                min-height: clamp(200px, 30vw, 400px);

                @include not-desktop {
                    grid-template:
                        "d" 1fr
                        "p" 1.618fr
                        / 100%;
                }
            }

            .blog-details {
                position: relative;
                grid-area: d;
                margin: 0;

                .blog-info {
                    margin: 0;
                    display: block;

                    .blog-date {
                        background-color: rgba(var(--blog-theme-primary),.8);
                        color: rgb(var(--blog-theme-primary-compliant));
                        height: 80px;
                        width: 80px;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        margin: 0;
                        font-size: 28px;
                        text-transform: uppercase;
                        display: flex;
                        line-height: 1;

                        span {
                            font-weight: bold;
                            font-size: .75em;
                        }
                    }
                }
            }

            .blog-image {
                grid-area: d-start / d-start / p-end / p-end;
                position: relative;
                margin: 0;

                img {
                    position: absolute;
                    z-index: -1;
                }
            }

            .blog-preview {
                position: relative;
                padding: 1rem;
                background-color: rgba(var(--blog-theme-primary),.8);

                &, & * {
                    color: rgb(var(--blog-theme-primary-compliant));
                }

                .read-more {
                    margin-top: 1rem;
                    color: rgb(var(--blog-theme-primary-compliant));
                }
            }
        }
    }
</style>